import React, { useState, useEffect } from "react";

import { fetchStatus } from "../api";

import Form from "./form";
import Success from "./success";
import Deploy from "./deploy";
import Error from "./error";

import Text from "../data/text.json";
import Wrapper from "./wrapper";

const SCREENS = {
  PROMPT_SCREEN: "PROMPT_SCREEN",
  FORM_SCREEN: "FORM_SCREEN",
  SUCCESS_SCREEN: "SUCCESS_SCREEN",
  PENDING_SCREEN: "PENDING_SCREEN",
  ERROR_SCREEN: "ERROR_SCREEN",
};

const Prompt = ({ width, signerAccountHash, onClickProcess }) => {
  return (
    <>
      <Wrapper
        width={width}
        content={
          <div className="popup__content">
            <h3 className="h3">{Text.text26}</h3>
            <p>{Text.text27}</p>
            <div className="nft-data__hash">{signerAccountHash}</div>
            <p>{Text.text28}</p>
            <button onClick={onClickProcess} className="btn btn--redeem btn--w">
              Redeem
            </button>
          </div>
        }
      />
    </>
  );
};

const Account = ({ width, signerAccountHash }) => {
  const [activeScreen, setActiveScreen] = useState(SCREENS.PROMPT_SCREEN);
  const [ownedNFT, setOwnedNFT] = useState(null);
  const [pendingDeploy, setPendingDeploy] = useState(null);
  const [error, setError] = useState(null);

  const getStatus = async () => {
    const { ok, val } = await fetchStatus(signerAccountHash);
    if (ok) {
      setOwnedNFT(val[0]);
      setError(null);
      setPendingDeploy(null);
      setActiveScreen(SCREENS.SUCCESS_SCREEN);
      return;
    }
    switch (val) {
      case "NOT_OWNED_TOKEN":
        setOwnedNFT(null);
        setError(null);
        setPendingDeploy(null);
        setActiveScreen(SCREENS.PROMPT_SCREEN);
        break;
      case "PENDING_DEPLOY":
        setOwnedNFT(null);
        setError(null);
        setActiveScreen(SCREENS.PENDING_SCREEN);
        break;
      default:
        setOwnedNFT(null);
        setError(val);
        setActiveScreen(SCREENS.ERROR_SCREEN);
        break;
    }
  };

  useEffect(() => {
    getStatus();
  }, [signerAccountHash]);

  if (!activeScreen) {
    return null;
  }

  if (ownedNFT) {
    return <Success tiger={ownedNFT} />;
  }

  if (error) {
    return <Error error={error} width={width} getStatus={getStatus} />;
  }

  switch (activeScreen) {
    case SCREENS.PROMPT_SCREEN:
      return (
        <Prompt
          width={width}
          signerAccountHash={signerAccountHash}
          onClickProcess={() => setActiveScreen(SCREENS.FORM_SCREEN)}
        />
      );
    case SCREENS.PENDING_SCREEN:
      return <Deploy width={width} getStatus={getStatus} hash={pendingDeploy} />;
    case SCREENS.FORM_SCREEN:
      return (
        <Form
          width={width}
          signerAccountHash={signerAccountHash}
          getStatus={getStatus}
          setError={setError}
          setPendingDeploy={setPendingDeploy}
        />
      );
    default:
      return null;
  }
};

export default Account;
