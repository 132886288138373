import React, { useState, useEffect } from "react";
import { Signer } from "casper-js-sdk";
import { Link } from "react-router-dom";

import cLogo2 from "../assets/casper-c-logo-2.svg";

import LanternMobile from "../assets/bg-img-lantern-mobile.svg";
import TigerDoorMobile from "../assets/bg-img-tiger-door-mobile.svg";
import desktopRequest from "../assets/desktop-request.svg";
import Text from "../data/text.json";
import { MOBILE_BREAKPOINT } from "../constants";

export const SignerConnector = ({ setSignerAccountHash }) => {
  const [isConnected, setConnected] = useState(false);

  useEffect(() => {
    setTimeout(async () => {
      try {
        const connected = await Signer.isConnected();
        setConnected(connected);
      } catch (err) {
        console.log(err);
      }
    }, 100);
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      try {
        const accountHash = await Signer.getActivePublicKey();
        setSignerAccountHash(accountHash);
      } catch (err) {
        console.log(err);
      }
    };
    if (isConnected) {
      asyncFn();
    }

    window.addEventListener("signer:locked", (msg) => {
      setSignerAccountHash(null);
    });
    window.addEventListener("signer:unlocked", (msg) => {
      if (msg.detail.isConnected) {
        setSignerAccountHash(msg.detail.activeKey);
      }
    });

    window.addEventListener("signer:activeKeyChanged", (msg) => {
      if (msg.detail.isConnected) {
        console.log(msg.detail.activeKey);
        setSignerAccountHash(msg.detail.activeKey);
      }
    });
    window.addEventListener("signer:connected", (msg) => {
      setSignerAccountHash(msg.detail.activeKey);
    });

    window.addEventListener("signer:disconnected", (msg) => {
      setSignerAccountHash(null);
    });
  }, [isConnected]);

  return null;
};

export const SignerScreen = ({ width }) => {
  return (
    <>
      <div className="popup">
        <div className="bg-img-container">
          <img src={LanternMobile} alt="lantern" className="img-lantern" />
          <img src={TigerDoorMobile} alt="bg-tiger-door" className="img-tiger-door" />
          <div className="overlay"></div>
        </div>
        <div className="popup__content popup__content--g20">
          {width < MOBILE_BREAKPOINT ? (
            <>
              <h3 className="h3">{Text.text45}</h3>
              <p>{Text.text46}</p>
              <img src={desktopRequest} alt="error" className="clogo--signer" />
              <Link to="/" className="btn btn--redeem btn--w">
                {Text.text47}
              </Link>
            </>
          ) : (
            <>
              <h3 className="h3">{Text.text29}</h3>
              <p>{Text.text30}</p>
              <img src={cLogo2} alt="casper-logo" className="clogo--signer" />
              <p className="font--14">
                {Text.text31} <b>{Text.text32}</b> {Text.text33}
              </p>
              <button className="btn btn--redeem btn--w" onClick={Signer.sendConnectionRequest}>
                {Text.text34}
              </button>
              <button className="btn btn--sec btn--w">{Text.text35}</button>
            </>
          )}
        </div>
      </div>
    </>
  );
};
