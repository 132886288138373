import React, { useState } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import Lottie from "react-lottie";

import { TOTAL_OF_NFTS } from "../constants";

import { animIntro } from "../assets/ct-motion-homepage-intro-desktop";
import { animIntroLoop } from "../assets/ct-motion-homepage-intro-loop-desktop";
import { animIntroMobile } from "../assets/ct-motion-homepage-intro-mobile";
import { animIntroLoopMobile } from "../assets/ct-motion-homepage-intro-loop-mobile";
import arrow from "../assets/arrow.svg";
import Text from "../data/text.json";

const Banner = ({ claimedNfts, width, introFinished, setIntroFinished }) => {
  const [counterAnim, setCounterAnim] = useState(false);

  //Counter size changer
  const counterFinish = async (time) => {
    await new Promise((res) => setTimeout(res, time));
    setCounterAnim(true);
  };

  //Animations options
  const introOptions = {
    loop: false,
    autoplay: true,
    animationData: animIntro,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const introLoopOptions = {
    loop: true,
    autoplay: true,
    animationData: animIntroLoop,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const introMobileOptions = {
    loop: false,
    autoplay: true,
    animationData: animIntroMobile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const introLoopMobileOptions = {
    loop: true,
    autoplay: true,
    animationData: animIntroLoopMobile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //Counter class name
  const counterClassName = () => {
    let name = "counter";
    if (!introFinished) {
      name = name.concat(" counter--anim");
    }
    if (counterAnim) {
      name = `${name} counter-finish`;
    }

    return name;
  };

  //Tigers plural
  const plural = () => {
    if (claimedNfts !== 1) {
      return "s";
    }
  };

  return (
    <>
      <div className="banner">
        <div className="banner__content">
          {width < 600 ? (
            <>
              <div className={introFinished ? "lottie-container lottie-container--invisible" : "lottie-container"}>
                <Lottie
                  options={introMobileOptions}
                  isClickToPauseDisabled={true}
                  eventListeners={[
                    {
                      eventName: "complete",
                      callback: () => setIntroFinished(true),
                    },
                  ]}
                />
              </div>
              <div className={!introFinished ? "lottie-container lottie-container--invisible" : "lottie-container"}>
                <Lottie options={introLoopMobileOptions} isStopped={!introFinished} isClickToPauseDisabled={true} />
              </div>
            </>
          ) : (
            <>
              <div className={introFinished ? "lottie-container lottie-container--invisible" : "lottie-container"}>
                <Lottie
                  options={introOptions}
                  isClickToPauseDisabled={true}
                  eventListeners={[
                    {
                      eventName: "complete",
                      callback: () => setIntroFinished(true),
                    },
                  ]}
                />
              </div>
              <div className={!introFinished ? "lottie-container lottie-container--invisible" : "lottie-container"}>
                <Lottie options={introLoopOptions} isStopped={!introFinished} isClickToPauseDisabled={true} />
              </div>
            </>
          )}
          <div className="banner__text">
            <h1 className={introFinished ? "banner__title h1" : "banner__title banner__title--anim h1"}>
              Welcome to CasperTigers
            </h1>
            <p className={introFinished ? "banner__desc" : "banner__desc banner__desc--anim"}>
              {Text.text2}
              <br />
              {Text.text3}
              <br />
              {Text.text4}
            </p>
            <Link to="/faq" className={introFinished ? "btn btn--faq" : "btn btn--faq btn--faq-anim"}>
              <div className="banner__btn">
                FAQ
                <img src={arrow} alt="arrow" />
              </div>
            </Link>
            <div className={counterClassName()}>
              <div className="counter__value">
                <CountUp
                  start={introFinished ? claimedNfts : 0}
                  end={claimedNfts}
                  duration={6.5}
                  delay={introFinished ? 0 : 6.5}
                  className="counter__value"
                  useEasing={true}
                  onStart={() => counterFinish(10000)}
                />
                /{TOTAL_OF_NFTS}
              </div>
              <div className="counter__desc">
                {Text.text5}
                {plural()}
                {Text.text5a}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
