import React, { useEffect, useState } from "react";

import Text from "../data/text.json";

import { MIDDLEWARE_URL } from "../constants";
import Wrapper from "./wrapper";

const Form = ({ width, signerAccountHash, getStatus, setError, setPendingDeploy }) => {
  const [redeemCode, setRedeemCode] = useState("");
  const [redeemCodeError, setRedeemCodeError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const REDEEM_CODE_REQUIRED_LENGTH = 10;

  //Validation
  const validateRedeemCode = () => {
    redeemCode.length !== REDEEM_CODE_REQUIRED_LENGTH ? setRedeemCodeError(true) : setRedeemCodeError(false);
  };
  const validateEmail = () => {
    email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? setEmailError(false) : setEmailError(true);
  };

  const reedemFn = () => {
    setButtonDisable(true);
    fetch(MIDDLEWARE_URL, {
      method: "PATCH",
      body: JSON.stringify({
        email,
        code: redeemCode,
        accountHash: `account-hash-${signerAccountHash}`,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((resp) => resp.json())
      .then(({ ok, val }) => {
        if (ok && val) {
          setPendingDeploy(val);
          getStatus();
        } else {
          setError(val);
        }
      });
  };

  useEffect(() => {
    if (email.length !== 0) {
      validateEmail();
    }
    if (redeemCode.length !== 0) {
      validateRedeemCode();
    }
  }, [redeemCode, email]);

  return (
    <>
      <Wrapper
        width={width}
        content={
          <div className="popup__content">
            <h3 className="h3">{Text.text23}</h3>
            <p>{Text.text24}</p>
            <p>{Text.text25}</p>
            <div className="input">
              <label className="input__label" htmlFor="redeem">
                Redeem code
              </label>
              <input
                className={redeemCodeError ? "input__input input__input--error" : "input__input"}
                type="text"
                id="redeem"
                name="redeem-code"
                required
                onChange={(e) => setRedeemCode(e.target.value)}
                value={redeemCode}
              />
            </div>
            <div className="input">
              <label className="input__label" htmlFor="email">
                E-mail
              </label>
              <input
                className={emailError ? "input__input input__input--error" : "input__input"}
                type="text"
                id="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="input">
              <label className="input__label" htmlFor="redeem-code">
                Your Account Hash
              </label>
              <input
                className="input__input input__input--light"
                type="text"
                id="redeem-code"
                name="redeem-code"
                readOnly
                disabled
                value={signerAccountHash}
              />
            </div>
            <button
              disabled={buttonDisable || redeemCodeError || emailError || email.length === 0 || redeemCode.length === 0}
              className={
                buttonDisable || redeemCodeError || emailError || email.length === 0 || redeemCode.length === 0
                  ? "btn btn--disabled btn--w"
                  : "btn btn--redeem btn--w"
              }
              onClick={reedemFn}
            >
              Redeem
            </button>
          </div>
        }
      />
    </>
  );
};

export default Form;
