import React from "react";

import pageArrow from "../assets/page-arrow.svg";

const pagination = ({ currentPage, maxPageNumber, nextPage, prevPage }) => {
  return (
    <nav className="pagination">
      <img className="btn btn--prev-page" onClick={() => prevPage(currentPage)} src={pageArrow} alt="" />
      <div className="pagination__page">
        {currentPage} of {maxPageNumber}
      </div>
      <img className="btn btn--next-page" onClick={() => nextPage(currentPage)} src={pageArrow} alt="" />
    </nav>
  );
};

export default pagination;
