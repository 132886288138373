import React from "react";
import ReactPlayer from "react-player";

import Text from "../data/text.json";

import { ASSETS_URL } from "../constants";
import Wrapper from "./wrapper";

const FaqItem = ({ question, answer }) => {
  return (
    <div className="faq__p">
      <h4 className="h4">{question}</h4>
      <p className="faq__p">{answer}</p>
    </div>
  );
};

const faq = ({ width }) => {
  return (
    <>
      <Wrapper
        width={width}
        content={
          <div className="popup__content popup__content--left faq">
            <div>
              <h3 className="h3">
                {Text.text7} <br />
                {Text.text8}
              </h3>
            </div>
            <ReactPlayer url={`${ASSETS_URL}explainer_v2.mp4`} controls width={"100%"} height={"min-content"} />
            <div className="faq__section">
              <h3 className="h3 h3--faq">{Text.text12}</h3>
              <FaqItem question={Text.text13} answer={Text.text14} />
              <FaqItem question={Text.text15} answer={Text.text16} />
              <FaqItem question={Text.text17} answer={Text.text18} />
              <div className="faq__p">
                <h4 className="h4">{Text.text19}</h4>
                <p className="faq__p">
                  {Text.text20}
                  <a className="hlink hlink--discord" href="https://discord.gg/casperblockchain" target="_blank">
                    {Text.text21}
                  </a>
                  {Text.text22}
                </p>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default faq;
