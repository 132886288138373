import img_0 from "../assets/anim-intro-desktop/img_0.png";
import img_1 from "../assets/anim-intro-desktop/img_1.png";
import img_2 from "../assets/anim-intro-desktop/img_2.png";
import img_3 from "../assets/anim-intro-desktop/img_3.png";
import img_4 from "../assets/anim-intro-desktop/img_4.png";
import img_5 from "../assets/anim-intro-desktop/img_5.png";
import img_6 from "../assets/anim-intro-desktop/img_6.png";
import img_7 from "../assets/anim-intro-desktop/img_7.png";
import img_8 from "../assets/anim-intro-desktop/img_8.png";
import img_9 from "../assets/anim-intro-desktop/img_9.png";
import img_10 from "../assets/anim-intro-desktop/img_10.png";
import img_11 from "../assets/anim-intro-desktop/img_11.png";
import img_12 from "../assets/anim-intro-desktop/img_12.png";
import img_13 from "../assets/anim-intro-desktop/img_13.png";

export const animIntro = {
  "v": "5.7.8",
  "fr": 30,
  "ip": 0,
  "op": 308,
  "w": 3100,
  "h": 852,
  "nm": "CT - motion - HomePage - intro - DESKTOP",
  "ddd": 0,
  "assets": [
    { "id": "image_0", "w": 248, "h": 731, "u": "", "p": img_0, "e": 0 },
    { "id": "image_1", "w": 69, "h": 73, "u": "", "p": img_1, "e": 0 },
    { "id": "image_2", "w": 266, "h": 226, "u": "", "p": img_2, "e": 0 },
    { "id": "image_3", "w": 991, "h": 27, "u": "", "p": img_3, "e": 0 },
    { "id": "image_4", "w": 712, "h": 441, "u": "", "p": img_4, "e": 0 },
    { "id": "image_5", "w": 280, "h": 217, "u": "", "p": img_5, "e": 0 },
    { "id": "image_6", "w": 142, "h": 199, "u": "", "p": img_6, "e": 0 },
    { "id": "image_7", "w": 126, "h": 305, "u": "", "p": img_7, "e": 0 },
    { "id": "image_8", "w": 240, "h": 310, "u": "", "p": img_8, "e": 0 },
    { "id": "image_9", "w": 381, "h": 216, "u": "", "p": img_9, "e": 0 },
    { "id": "image_10", "w": 177, "h": 626, "u": "", "p": img_10, "e": 0 },
    { "id": "image_11", "w": 178, "h": 641, "u": "", "p": img_11, "e": 0 },
    { "id": "image_12", "w": 857, "h": 37, "u": "", "p": img_12, "e": 0 },
    { "id": "image_13", "w": 1110, "h": 36, "u": "", "p": img_13, "e": 0 }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 3,
      "nm": "MASTER NULL",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 0, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [1184, 426, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "Dust 6",
      "parent": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 50, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [778.5, 90, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [70, 70, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "t": 184,
                    "s": [
                      {
                        "i": [
                          [12, 4],
                          [6, -21],
                          [-5.885, -3.942],
                          [-5.291, 0],
                          [0, 0],
                          [12, 8]
                        ],
                        "o": [
                          [-12, -4],
                          [-2.825, 9.889],
                          [6.612, 4.429],
                          [10, 0],
                          [0, 0],
                          [-12, -8]
                        ],
                        "v": [
                          [-125, 335],
                          [-150, 351],
                          [-143.114, 371.171],
                          [-122, 377],
                          [-76, 378],
                          [-97, 362]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 187,
                    "s": [
                      {
                        "i": [
                          [25, 11],
                          [5.913, -21.025],
                          [-6.018, -8.103],
                          [-12, -17],
                          [0, 0],
                          [31, 19]
                        ],
                        "o": [
                          [-19.567, -8.61],
                          [-3.963, 14.092],
                          [7.647, 10.298],
                          [26, 0],
                          [0, 0],
                          [-23.643, -14.491]
                        ],
                        "v": [
                          [-202, 304],
                          [-252, 326],
                          [-238.509, 361.378],
                          [-261, 377],
                          [-96, 378],
                          [-179, 352]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 190,
                    "s": [
                      {
                        "i": [
                          [7.263, 26.329],
                          [33, -4],
                          [-24.491, -43.378],
                          [-70, -15],
                          [0, 0],
                          [22.568, 28.507]
                        ],
                        "o": [
                          [-8, -29],
                          [-21.772, 2.639],
                          [-23.491, -16.378],
                          [26, 0],
                          [0, 0],
                          [-19, -24]
                        ],
                        "v": [
                          [-270, 289],
                          [-324, 253],
                          [-340.509, 333.378],
                          [-330, 377],
                          [-126, 378],
                          [-232, 333]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 193,
                    "s": [
                      {
                        "i": [
                          [0, 27.313],
                          [33, -4],
                          [-24.491, -43.378],
                          [-57, -18],
                          [0, 0],
                          [11, 41]
                        ],
                        "o": [
                          [0, -35],
                          [-21.772, 2.639],
                          [-23.491, -16.378],
                          [26, 0],
                          [0, 0],
                          [-7.932, -29.565]
                        ],
                        "v": [
                          [-310, 289],
                          [-357, 235],
                          [-391.509, 313.378],
                          [-388, 377],
                          [-166, 378],
                          [-263, 323]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 196,
                    "s": [
                      {
                        "i": [
                          [-9.86, 25.471],
                          [37.013, 24.29],
                          [-10.491, -64.378],
                          [-57, -18],
                          [-30, 0],
                          [11, 41]
                        ],
                        "o": [
                          [12, -31],
                          [-32, -21],
                          [-23.491, -16.378],
                          [26, 0],
                          [-38, -16],
                          [-7.932, -29.565]
                        ],
                        "v": [
                          [-320, 289],
                          [-337, 206],
                          [-419.509, 270.378],
                          [-413, 377],
                          [-209, 378],
                          [-265, 300]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 199,
                    "s": [
                      {
                        "i": [
                          [-9.86, 25.471],
                          [28.342, 34.011],
                          [41.509, -77.378],
                          [-57, -18],
                          [-30, 0],
                          [-4.96, 42.159]
                        ],
                        "o": [
                          [12, -31],
                          [-25, -30],
                          [-23.491, -16.378],
                          [26, 0],
                          [-38, -16],
                          [4, -34]
                        ],
                        "v": [
                          [-310, 266],
                          [-287, 175],
                          [-419.509, 230.378],
                          [-431, 377],
                          [-263, 378],
                          [-274, 311]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 202,
                    "s": [
                      {
                        "i": [
                          [-22.613, 15.318],
                          [-6.488, 43.794],
                          [56.509, -63.378],
                          [-60, -74],
                          [-30, 0],
                          [-28.202, 31.728]
                        ],
                        "o": [
                          [31, -21],
                          [4, -27],
                          [-44.491, -27.378],
                          [26, 0],
                          [17, -38],
                          [16, -18]
                        ],
                        "v": [
                          [-285, 272],
                          [-238, 209],
                          [-395.509, 203.378],
                          [-439, 377],
                          [-346, 378],
                          [-274, 322]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 205,
                    "s": [
                      {
                        "i": [
                          [-24.932, 8.58],
                          [-17.077, 39.178],
                          [68.626, -44.83],
                          [-58.5, -128.5],
                          [-30, 0],
                          [-32.387, 9.611]
                        ],
                        "o": [
                          [34.179, -11.762],
                          [10.528, -24.154],
                          [-34.566, -36.721],
                          [26, 0],
                          [1, -41],
                          [19.394, -5.104]
                        ],
                        "v": [
                          [-305.871, 286.386],
                          [-259.203, 248.513],
                          [-380.573, 191.602],
                          [-439, 377],
                          [-367.5, 378],
                          [-322.714, 321.253]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 208,
                    "s": [
                      {
                        "i": [
                          [-27.251, 1.842],
                          [-44.092, 3.988],
                          [80.742, -26.283],
                          [-57, -183],
                          [-30, 0],
                          [-36.573, -12.506]
                        ],
                        "o": [
                          [37.358, -2.525],
                          [22.405, -2.026],
                          [-24.641, -46.063],
                          [26, 0],
                          [-15, -44],
                          [22.788, 7.792]
                        ],
                        "v": [
                          [-357.742, 279.773],
                          [-300.405, 288.026],
                          [-385.637, 179.825],
                          [-446, 377],
                          [-431, 378],
                          [-410.427, 303.506]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 211,
                    "s": [
                      {
                        "i": [
                          [-23.732, -8.264],
                          [-38.761, -12.518],
                          [46.893, 20.812],
                          [-14.368, -119.763],
                          [-30, 0],
                          [-30.241, -13.975]
                        ],
                        "o": [
                          [32.534, 11.33],
                          [19.696, 6.361],
                          [-18.842, -56.985],
                          [-2.5, 31],
                          [-15, -44],
                          [21.509, 10.429]
                        ],
                        "v": [
                          [-381.839, 270.473],
                          [-355.278, 301.128],
                          [-368.297, 206.865],
                          [-465.5, 289],
                          [-432, 360],
                          [-421.259, 267.975]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 214,
                    "s": [
                      {
                        "i": [
                          [-6.639, -26.494],
                          [-1.97, -11.201],
                          [-20.038, 44.439],
                          [54.858, -31.378],
                          [-24.969, -16.63],
                          [-28.398, 1.928]
                        ],
                        "o": [
                          [9.101, 36.32],
                          [1.835, 10.436],
                          [28.423, -63.036],
                          [-60.17, 34.417],
                          [11.907, -44.936],
                          [25.282, -1.716]
                        ],
                        "v": [
                          [-404.887, 258.195],
                          [-444.463, 302.792],
                          [-372.854, 276.807],
                          [-459.24, 177.329],
                          [-487.2, 300.484],
                          [-433.474, 214.247]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 217,
                    "s": [
                      {
                        "i": [
                          [4.786, -22.596],
                          [5.507, 2.027],
                          [-23.091, 30.592],
                          [50.951, -5.492],
                          [-17.025, -22.611],
                          [-25.881, -7.164]
                        ],
                        "o": [
                          [-7.713, 32.306],
                          [0.41, 11.346],
                          [36.14, -44.145],
                          [-42.352, 11.891],
                          [18.897, -43.575],
                          [23.041, 6.378]
                        ],
                        "v": [
                          [-408.049, 259.447],
                          [-461.724, 278.769],
                          [-397.355, 280.531],
                          [-438.642, 174.968],
                          [-494.543, 259.349],
                          [-425.752, 205.387]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 220,
                    "s": [
                      {
                        "i": [
                          [16.21, -18.698],
                          [12.984, 15.255],
                          [-26.144, 16.745],
                          [47.045, 20.394],
                          [8.887, -22.215],
                          [-23.364, -16.257]
                        ],
                        "o": [
                          [-24.527, 28.292],
                          [-1.016, 12.255],
                          [43.856, -25.255],
                          [-24.534, -10.635],
                          [23.887, -17.215],
                          [20.8, 14.473]
                        ],
                        "v": [
                          [-411.21, 260.698],
                          [-478.984, 254.745],
                          [-421.856, 284.255],
                          [-416.045, 181.606],
                          [-477.887, 196.215],
                          [-418.03, 196.527]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 223,
                    "s": [
                      {
                        "i": [
                          [24.015, -5.971],
                          [8.393, 18.189],
                          [-29.689, 9.082],
                          [25.879, 32.153],
                          [20.697, -5.302],
                          [-9.862, -11.23]
                        ],
                        "o": [
                          [-18.64, 4.635],
                          [1.023, 10.628],
                          [37.959, -13.182],
                          [-12.794, -15.895],
                          [22.697, -1.302],
                          [23.754, 27.049]
                        ],
                        "v": [
                          [-431.36, 270.365],
                          [-469.023, 255.372],
                          [-429.459, 275.682],
                          [-401.706, 193.895],
                          [-451.197, 185.302],
                          [-407.638, 199.73]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 226,
                    "s": [
                      {
                        "i": [
                          [23.607, 7.423],
                          [-2.319, 19.898],
                          [-20.979, -6.02],
                          [-4.297, 30.308],
                          [14.458, 15.441],
                          [3.092, -14.622]
                        ],
                        "o": [
                          [-18.323, -5.762],
                          [-3.304, 7.937],
                          [19.021, 7.48],
                          [2.864, -20.202],
                          [11.958, 16.941],
                          [-5.694, 26.921]
                        ],
                        "v": [
                          [-469.871, 272.492],
                          [-494.196, 240.063],
                          [-470.021, 273.52],
                          [-416.203, 245.692],
                          [-426.458, 208.559],
                          [-418.306, 246.079]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [0.898039275525, 0.725490196078, 0.384313755409, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 184,
      "op": 229,
      "st": 82,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "Dust 5",
      "parent": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 50, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [647.5, 90, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [-70, 70, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "t": 183,
                    "s": [
                      {
                        "i": [
                          [12, 4],
                          [6, -21],
                          [-5.885, -3.942],
                          [-5.291, 0],
                          [0, 0],
                          [12, 8]
                        ],
                        "o": [
                          [-12, -4],
                          [-2.825, 9.889],
                          [6.612, 4.429],
                          [10, 0],
                          [0, 0],
                          [-12, -8]
                        ],
                        "v": [
                          [-125, 335],
                          [-150, 351],
                          [-143.114, 371.171],
                          [-122, 377],
                          [-76, 378],
                          [-97, 362]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 186,
                    "s": [
                      {
                        "i": [
                          [25, 11],
                          [5.913, -21.025],
                          [-6.018, -8.103],
                          [-12, -17],
                          [0, 0],
                          [31, 19]
                        ],
                        "o": [
                          [-19.567, -8.61],
                          [-3.963, 14.092],
                          [7.647, 10.298],
                          [26, 0],
                          [0, 0],
                          [-23.643, -14.491]
                        ],
                        "v": [
                          [-202, 304],
                          [-252, 326],
                          [-238.509, 361.378],
                          [-261, 377],
                          [-96, 378],
                          [-179, 352]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 189,
                    "s": [
                      {
                        "i": [
                          [7.263, 26.329],
                          [33, -4],
                          [-24.491, -43.378],
                          [-70, -15],
                          [0, 0],
                          [22.568, 28.507]
                        ],
                        "o": [
                          [-8, -29],
                          [-21.772, 2.639],
                          [-23.491, -16.378],
                          [26, 0],
                          [0, 0],
                          [-19, -24]
                        ],
                        "v": [
                          [-270, 289],
                          [-324, 253],
                          [-340.509, 333.378],
                          [-330, 377],
                          [-126, 378],
                          [-232, 333]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 192,
                    "s": [
                      {
                        "i": [
                          [0, 27.313],
                          [33, -4],
                          [-24.491, -43.378],
                          [-57, -18],
                          [0, 0],
                          [11, 41]
                        ],
                        "o": [
                          [0, -35],
                          [-21.772, 2.639],
                          [-23.491, -16.378],
                          [26, 0],
                          [0, 0],
                          [-7.932, -29.565]
                        ],
                        "v": [
                          [-310, 289],
                          [-357, 235],
                          [-391.509, 313.378],
                          [-388, 377],
                          [-166, 378],
                          [-263, 323]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 195,
                    "s": [
                      {
                        "i": [
                          [-9.86, 25.471],
                          [37.013, 24.29],
                          [-10.491, -64.378],
                          [-57, -18],
                          [-30, 0],
                          [11, 41]
                        ],
                        "o": [
                          [12, -31],
                          [-32, -21],
                          [-23.491, -16.378],
                          [26, 0],
                          [-38, -16],
                          [-7.932, -29.565]
                        ],
                        "v": [
                          [-320, 289],
                          [-337, 206],
                          [-419.509, 270.378],
                          [-413, 377],
                          [-209, 378],
                          [-265, 300]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 198,
                    "s": [
                      {
                        "i": [
                          [-9.86, 25.471],
                          [28.342, 34.011],
                          [41.509, -77.378],
                          [-57, -18],
                          [-30, 0],
                          [-4.96, 42.159]
                        ],
                        "o": [
                          [12, -31],
                          [-25, -30],
                          [-23.491, -16.378],
                          [26, 0],
                          [-38, -16],
                          [4, -34]
                        ],
                        "v": [
                          [-310, 266],
                          [-287, 175],
                          [-419.509, 230.378],
                          [-431, 377],
                          [-263, 378],
                          [-274, 311]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 201,
                    "s": [
                      {
                        "i": [
                          [-22.613, 15.318],
                          [-6.488, 43.794],
                          [56.509, -63.378],
                          [-60, -74],
                          [-30, 0],
                          [-28.202, 31.728]
                        ],
                        "o": [
                          [31, -21],
                          [4, -27],
                          [-44.491, -27.378],
                          [26, 0],
                          [17, -38],
                          [16, -18]
                        ],
                        "v": [
                          [-285, 272],
                          [-238, 209],
                          [-395.509, 203.378],
                          [-439, 377],
                          [-346, 378],
                          [-274, 322]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 204,
                    "s": [
                      {
                        "i": [
                          [-24.932, 8.58],
                          [-17.077, 39.178],
                          [68.626, -44.83],
                          [-58.5, -128.5],
                          [-30, 0],
                          [-32.387, 9.611]
                        ],
                        "o": [
                          [34.179, -11.762],
                          [10.528, -24.154],
                          [-34.566, -36.721],
                          [26, 0],
                          [1, -41],
                          [19.394, -5.104]
                        ],
                        "v": [
                          [-305.871, 286.386],
                          [-259.203, 248.513],
                          [-380.573, 191.602],
                          [-439, 377],
                          [-367.5, 378],
                          [-322.714, 321.253]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 208,
                    "s": [
                      {
                        "i": [
                          [-27.251, 1.842],
                          [-44.092, 3.988],
                          [80.742, -26.283],
                          [-57, -183],
                          [-30, 0],
                          [-36.573, -12.506]
                        ],
                        "o": [
                          [37.358, -2.525],
                          [22.405, -2.026],
                          [-24.641, -46.063],
                          [26, 0],
                          [-15, -44],
                          [22.788, 7.792]
                        ],
                        "v": [
                          [-357.742, 279.773],
                          [-300.405, 288.026],
                          [-385.637, 179.825],
                          [-446, 377],
                          [-431, 378],
                          [-410.427, 303.506]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 211,
                    "s": [
                      {
                        "i": [
                          [-23.732, -8.264],
                          [-38.761, -12.518],
                          [46.893, 20.812],
                          [-14.368, -119.763],
                          [-30, 0],
                          [-30.241, -13.975]
                        ],
                        "o": [
                          [32.534, 11.33],
                          [19.696, 6.361],
                          [-18.842, -56.985],
                          [-2.5, 31],
                          [-15, -44],
                          [21.509, 10.429]
                        ],
                        "v": [
                          [-381.839, 270.473],
                          [-355.278, 301.128],
                          [-368.297, 206.865],
                          [-465.5, 289],
                          [-432, 360],
                          [-421.259, 267.975]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 214,
                    "s": [
                      {
                        "i": [
                          [-6.639, -26.494],
                          [-1.97, -11.201],
                          [-20.038, 44.439],
                          [54.858, -31.378],
                          [-24.969, -16.63],
                          [-28.398, 1.928]
                        ],
                        "o": [
                          [9.101, 36.32],
                          [1.835, 10.436],
                          [28.423, -63.036],
                          [-60.17, 34.417],
                          [11.907, -44.936],
                          [25.282, -1.716]
                        ],
                        "v": [
                          [-404.887, 258.195],
                          [-444.463, 302.792],
                          [-372.854, 276.807],
                          [-459.24, 177.329],
                          [-487.2, 300.484],
                          [-433.474, 214.247]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 217,
                    "s": [
                      {
                        "i": [
                          [4.786, -22.596],
                          [5.507, 2.027],
                          [-23.091, 30.592],
                          [50.951, -5.492],
                          [-17.025, -22.611],
                          [-25.881, -7.164]
                        ],
                        "o": [
                          [-7.713, 32.306],
                          [0.41, 11.346],
                          [36.14, -44.145],
                          [-42.352, 11.891],
                          [18.897, -43.575],
                          [23.041, 6.378]
                        ],
                        "v": [
                          [-408.049, 259.447],
                          [-461.724, 278.769],
                          [-397.355, 280.531],
                          [-438.642, 174.968],
                          [-494.543, 259.349],
                          [-425.752, 205.387]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 220,
                    "s": [
                      {
                        "i": [
                          [16.21, -18.698],
                          [12.984, 15.255],
                          [-26.144, 16.745],
                          [47.045, 20.394],
                          [8.887, -22.215],
                          [-23.364, -16.257]
                        ],
                        "o": [
                          [-24.527, 28.292],
                          [-1.016, 12.255],
                          [43.856, -25.255],
                          [-24.534, -10.635],
                          [23.887, -17.215],
                          [20.8, 14.473]
                        ],
                        "v": [
                          [-411.21, 260.698],
                          [-478.984, 254.745],
                          [-421.856, 284.255],
                          [-416.045, 181.606],
                          [-477.887, 196.215],
                          [-418.03, 196.527]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 223,
                    "s": [
                      {
                        "i": [
                          [24.015, -5.971],
                          [8.393, 18.189],
                          [-29.689, 9.082],
                          [25.879, 32.153],
                          [20.697, -5.302],
                          [-9.862, -11.23]
                        ],
                        "o": [
                          [-18.64, 4.635],
                          [1.023, 10.628],
                          [37.959, -13.182],
                          [-12.794, -15.895],
                          [22.697, -1.302],
                          [23.754, 27.049]
                        ],
                        "v": [
                          [-431.36, 270.365],
                          [-469.023, 255.372],
                          [-429.459, 275.682],
                          [-401.706, 193.895],
                          [-451.197, 185.302],
                          [-407.638, 199.73]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 226,
                    "s": [
                      {
                        "i": [
                          [23.607, 7.423],
                          [-2.319, 19.898],
                          [-20.979, -6.02],
                          [-4.297, 30.308],
                          [14.458, 15.441],
                          [3.092, -14.622]
                        ],
                        "o": [
                          [-18.323, -5.762],
                          [-3.304, 7.937],
                          [19.021, 7.48],
                          [2.864, -20.202],
                          [11.958, 16.941],
                          [-5.694, 26.921]
                        ],
                        "v": [
                          [-469.871, 272.492],
                          [-494.196, 240.063],
                          [-470.021, 273.52],
                          [-416.203, 245.692],
                          [-426.458, 208.559],
                          [-418.306, 246.079]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [0.898039275525, 0.725490196078, 0.384313755409, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 183,
      "op": 228,
      "st": 81,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "Dust 2",
      "parent": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 50, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [350.5, 0, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "t": 103,
                    "s": [
                      {
                        "i": [
                          [12, 4],
                          [6, -21],
                          [-5.885, -3.942],
                          [-5.291, 0],
                          [0, 0],
                          [12, 8]
                        ],
                        "o": [
                          [-12, -4],
                          [-2.825, 9.889],
                          [6.612, 4.429],
                          [10, 0],
                          [0, 0],
                          [-12, -8]
                        ],
                        "v": [
                          [-125, 335],
                          [-150, 351],
                          [-143.114, 371.171],
                          [-122, 377],
                          [-76, 378],
                          [-97, 362]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 106,
                    "s": [
                      {
                        "i": [
                          [25, 11],
                          [5.913, -21.025],
                          [-6.018, -8.103],
                          [-12, -17],
                          [0, 0],
                          [31, 19]
                        ],
                        "o": [
                          [-19.567, -8.61],
                          [-3.963, 14.092],
                          [7.647, 10.298],
                          [26, 0],
                          [0, 0],
                          [-23.643, -14.491]
                        ],
                        "v": [
                          [-202, 304],
                          [-252, 326],
                          [-238.509, 361.378],
                          [-261, 377],
                          [-96, 378],
                          [-179, 352]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 109,
                    "s": [
                      {
                        "i": [
                          [7.263, 26.329],
                          [33, -4],
                          [-24.491, -43.378],
                          [-70, -15],
                          [0, 0],
                          [22.568, 28.507]
                        ],
                        "o": [
                          [-8, -29],
                          [-21.772, 2.639],
                          [-23.491, -16.378],
                          [26, 0],
                          [0, 0],
                          [-19, -24]
                        ],
                        "v": [
                          [-270, 289],
                          [-324, 253],
                          [-340.509, 333.378],
                          [-330, 377],
                          [-126, 378],
                          [-232, 333]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 112,
                    "s": [
                      {
                        "i": [
                          [0, 27.313],
                          [33, -4],
                          [-24.491, -43.378],
                          [-57, -18],
                          [0, 0],
                          [11, 41]
                        ],
                        "o": [
                          [0, -35],
                          [-21.772, 2.639],
                          [-23.491, -16.378],
                          [26, 0],
                          [0, 0],
                          [-7.932, -29.565]
                        ],
                        "v": [
                          [-310, 289],
                          [-357, 235],
                          [-391.509, 313.378],
                          [-388, 377],
                          [-166, 378],
                          [-263, 323]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 115,
                    "s": [
                      {
                        "i": [
                          [-9.86, 25.471],
                          [37.013, 24.29],
                          [-10.491, -64.378],
                          [-57, -18],
                          [-30, 0],
                          [11, 41]
                        ],
                        "o": [
                          [12, -31],
                          [-32, -21],
                          [-23.491, -16.378],
                          [26, 0],
                          [-38, -16],
                          [-7.932, -29.565]
                        ],
                        "v": [
                          [-320, 289],
                          [-337, 206],
                          [-419.509, 270.378],
                          [-413, 377],
                          [-209, 378],
                          [-265, 300]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 118,
                    "s": [
                      {
                        "i": [
                          [-9.86, 25.471],
                          [28.342, 34.011],
                          [41.509, -77.378],
                          [-57, -18],
                          [-30, 0],
                          [-4.96, 42.159]
                        ],
                        "o": [
                          [12, -31],
                          [-25, -30],
                          [-23.491, -16.378],
                          [26, 0],
                          [-38, -16],
                          [4, -34]
                        ],
                        "v": [
                          [-310, 266],
                          [-287, 175],
                          [-419.509, 230.378],
                          [-431, 377],
                          [-263, 378],
                          [-274, 311]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 121,
                    "s": [
                      {
                        "i": [
                          [-22.613, 15.318],
                          [-6.488, 43.794],
                          [56.509, -63.378],
                          [-60, -74],
                          [-30, 0],
                          [-28.202, 31.728]
                        ],
                        "o": [
                          [31, -21],
                          [4, -27],
                          [-44.491, -27.378],
                          [26, 0],
                          [17, -38],
                          [16, -18]
                        ],
                        "v": [
                          [-285, 272],
                          [-238, 209],
                          [-395.509, 203.378],
                          [-439, 377],
                          [-346, 378],
                          [-274, 322]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 124,
                    "s": [
                      {
                        "i": [
                          [-24.932, 8.58],
                          [-17.077, 39.178],
                          [68.626, -44.83],
                          [-58.5, -128.5],
                          [-30, 0],
                          [-32.387, 9.611]
                        ],
                        "o": [
                          [34.179, -11.762],
                          [10.528, -24.154],
                          [-34.566, -36.721],
                          [26, 0],
                          [1, -41],
                          [19.394, -5.104]
                        ],
                        "v": [
                          [-305.871, 286.386],
                          [-259.203, 248.513],
                          [-380.573, 191.602],
                          [-439, 377],
                          [-367.5, 378],
                          [-322.714, 321.253]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 127,
                    "s": [
                      {
                        "i": [
                          [-27.251, 1.842],
                          [-44.092, 3.988],
                          [80.742, -26.283],
                          [-57, -183],
                          [-30, 0],
                          [-36.573, -12.506]
                        ],
                        "o": [
                          [37.358, -2.525],
                          [22.405, -2.026],
                          [-24.641, -46.063],
                          [26, 0],
                          [-15, -44],
                          [22.788, 7.792]
                        ],
                        "v": [
                          [-357.742, 279.773],
                          [-300.405, 288.026],
                          [-385.637, 179.825],
                          [-446, 377],
                          [-431, 378],
                          [-410.427, 303.506]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 130,
                    "s": [
                      {
                        "i": [
                          [-23.732, -8.264],
                          [-38.761, -12.518],
                          [46.893, 20.812],
                          [-14.368, -119.763],
                          [-30, 0],
                          [-30.241, -13.975]
                        ],
                        "o": [
                          [32.534, 11.33],
                          [19.696, 6.361],
                          [-18.842, -56.985],
                          [-2.5, 31],
                          [-15, -44],
                          [21.509, 10.429]
                        ],
                        "v": [
                          [-381.839, 270.473],
                          [-355.278, 301.128],
                          [-368.297, 206.865],
                          [-465.5, 289],
                          [-432, 360],
                          [-421.259, 267.975]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 133,
                    "s": [
                      {
                        "i": [
                          [-6.639, -26.494],
                          [-1.97, -11.201],
                          [-20.038, 44.439],
                          [54.858, -31.378],
                          [-24.969, -16.63],
                          [-28.398, 1.928]
                        ],
                        "o": [
                          [9.101, 36.32],
                          [1.835, 10.436],
                          [28.423, -63.036],
                          [-60.17, 34.417],
                          [11.907, -44.936],
                          [25.282, -1.716]
                        ],
                        "v": [
                          [-404.887, 258.195],
                          [-444.463, 302.792],
                          [-372.854, 276.807],
                          [-459.24, 177.329],
                          [-487.2, 300.484],
                          [-433.474, 214.247]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 136,
                    "s": [
                      {
                        "i": [
                          [4.786, -22.596],
                          [5.507, 2.027],
                          [-23.091, 30.592],
                          [50.951, -5.492],
                          [-17.025, -22.611],
                          [-25.881, -7.164]
                        ],
                        "o": [
                          [-7.713, 32.306],
                          [0.41, 11.346],
                          [36.14, -44.145],
                          [-42.352, 11.891],
                          [18.897, -43.575],
                          [23.041, 6.378]
                        ],
                        "v": [
                          [-408.049, 259.447],
                          [-461.724, 278.769],
                          [-397.355, 280.531],
                          [-438.642, 174.968],
                          [-494.543, 259.349],
                          [-425.752, 205.387]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 139,
                    "s": [
                      {
                        "i": [
                          [16.21, -18.698],
                          [12.984, 15.255],
                          [-26.144, 16.745],
                          [47.045, 20.394],
                          [8.887, -22.215],
                          [-23.364, -16.257]
                        ],
                        "o": [
                          [-24.527, 28.292],
                          [-1.016, 12.255],
                          [43.856, -25.255],
                          [-24.534, -10.635],
                          [23.887, -17.215],
                          [20.8, 14.473]
                        ],
                        "v": [
                          [-411.21, 260.698],
                          [-478.984, 254.745],
                          [-421.856, 284.255],
                          [-416.045, 181.606],
                          [-477.887, 196.215],
                          [-418.03, 196.527]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 142,
                    "s": [
                      {
                        "i": [
                          [24.015, -5.971],
                          [8.393, 18.189],
                          [-29.689, 9.082],
                          [25.879, 32.153],
                          [20.697, -5.302],
                          [-9.862, -11.23]
                        ],
                        "o": [
                          [-18.64, 4.635],
                          [1.023, 10.628],
                          [37.959, -13.182],
                          [-12.794, -15.895],
                          [22.697, -1.302],
                          [23.754, 27.049]
                        ],
                        "v": [
                          [-431.36, 270.365],
                          [-469.023, 255.372],
                          [-429.459, 275.682],
                          [-401.706, 193.895],
                          [-451.197, 185.302],
                          [-407.638, 199.73]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 145,
                    "s": [
                      {
                        "i": [
                          [23.607, 7.423],
                          [-2.319, 19.898],
                          [-20.979, -6.02],
                          [-4.297, 30.308],
                          [14.458, 15.441],
                          [3.092, -14.622]
                        ],
                        "o": [
                          [-18.323, -5.762],
                          [-3.304, 7.937],
                          [19.021, 7.48],
                          [2.864, -20.202],
                          [11.958, 16.941],
                          [-5.694, 26.921]
                        ],
                        "v": [
                          [-469.871, 272.492],
                          [-494.196, 240.063],
                          [-470.021, 273.52],
                          [-416.203, 245.692],
                          [-426.458, 208.559],
                          [-418.306, 246.079]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [0.898039275525, 0.725490196078, 0.384313755409, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 103,
      "op": 148,
      "st": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "Dust",
      "parent": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 50, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [530.5, 0, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [-100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "t": 102,
                    "s": [
                      {
                        "i": [
                          [12, 4],
                          [6, -21],
                          [-5.885, -3.942],
                          [-5.291, 0],
                          [0, 0],
                          [12, 8]
                        ],
                        "o": [
                          [-12, -4],
                          [-2.825, 9.889],
                          [6.612, 4.429],
                          [10, 0],
                          [0, 0],
                          [-12, -8]
                        ],
                        "v": [
                          [-125, 335],
                          [-150, 351],
                          [-143.114, 371.171],
                          [-122, 377],
                          [-76, 378],
                          [-97, 362]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 105,
                    "s": [
                      {
                        "i": [
                          [25, 11],
                          [5.913, -21.025],
                          [-6.018, -8.103],
                          [-12, -17],
                          [0, 0],
                          [31, 19]
                        ],
                        "o": [
                          [-19.567, -8.61],
                          [-3.963, 14.092],
                          [7.647, 10.298],
                          [26, 0],
                          [0, 0],
                          [-23.643, -14.491]
                        ],
                        "v": [
                          [-202, 304],
                          [-252, 326],
                          [-238.509, 361.378],
                          [-261, 377],
                          [-96, 378],
                          [-179, 352]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 108,
                    "s": [
                      {
                        "i": [
                          [7.263, 26.329],
                          [33, -4],
                          [-24.491, -43.378],
                          [-70, -15],
                          [0, 0],
                          [22.568, 28.507]
                        ],
                        "o": [
                          [-8, -29],
                          [-21.772, 2.639],
                          [-23.491, -16.378],
                          [26, 0],
                          [0, 0],
                          [-19, -24]
                        ],
                        "v": [
                          [-270, 289],
                          [-324, 253],
                          [-340.509, 333.378],
                          [-330, 377],
                          [-126, 378],
                          [-232, 333]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 111,
                    "s": [
                      {
                        "i": [
                          [0, 27.313],
                          [33, -4],
                          [-24.491, -43.378],
                          [-57, -18],
                          [0, 0],
                          [11, 41]
                        ],
                        "o": [
                          [0, -35],
                          [-21.772, 2.639],
                          [-23.491, -16.378],
                          [26, 0],
                          [0, 0],
                          [-7.932, -29.565]
                        ],
                        "v": [
                          [-310, 289],
                          [-357, 235],
                          [-391.509, 313.378],
                          [-388, 377],
                          [-166, 378],
                          [-263, 323]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 114,
                    "s": [
                      {
                        "i": [
                          [-9.86, 25.471],
                          [37.013, 24.29],
                          [-10.491, -64.378],
                          [-57, -18],
                          [-30, 0],
                          [11, 41]
                        ],
                        "o": [
                          [12, -31],
                          [-32, -21],
                          [-23.491, -16.378],
                          [26, 0],
                          [-38, -16],
                          [-7.932, -29.565]
                        ],
                        "v": [
                          [-320, 289],
                          [-337, 206],
                          [-419.509, 270.378],
                          [-413, 377],
                          [-209, 378],
                          [-265, 300]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 117,
                    "s": [
                      {
                        "i": [
                          [-9.86, 25.471],
                          [28.342, 34.011],
                          [41.509, -77.378],
                          [-57, -18],
                          [-30, 0],
                          [-4.96, 42.159]
                        ],
                        "o": [
                          [12, -31],
                          [-25, -30],
                          [-23.491, -16.378],
                          [26, 0],
                          [-38, -16],
                          [4, -34]
                        ],
                        "v": [
                          [-310, 266],
                          [-287, 175],
                          [-419.509, 230.378],
                          [-431, 377],
                          [-263, 378],
                          [-274, 311]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 120,
                    "s": [
                      {
                        "i": [
                          [-22.613, 15.318],
                          [-6.488, 43.794],
                          [56.509, -63.378],
                          [-60, -74],
                          [-30, 0],
                          [-28.202, 31.728]
                        ],
                        "o": [
                          [31, -21],
                          [4, -27],
                          [-44.491, -27.378],
                          [26, 0],
                          [17, -38],
                          [16, -18]
                        ],
                        "v": [
                          [-285, 272],
                          [-238, 209],
                          [-395.509, 203.378],
                          [-439, 377],
                          [-346, 378],
                          [-274, 322]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 123,
                    "s": [
                      {
                        "i": [
                          [-24.932, 8.58],
                          [-17.077, 39.178],
                          [68.626, -44.83],
                          [-58.5, -128.5],
                          [-30, 0],
                          [-32.387, 9.611]
                        ],
                        "o": [
                          [34.179, -11.762],
                          [10.528, -24.154],
                          [-34.566, -36.721],
                          [26, 0],
                          [1, -41],
                          [19.394, -5.104]
                        ],
                        "v": [
                          [-305.871, 286.386],
                          [-259.203, 248.513],
                          [-380.573, 191.602],
                          [-439, 377],
                          [-367.5, 378],
                          [-322.714, 321.253]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 127,
                    "s": [
                      {
                        "i": [
                          [-27.251, 1.842],
                          [-44.092, 3.988],
                          [80.742, -26.283],
                          [-57, -183],
                          [-30, 0],
                          [-36.573, -12.506]
                        ],
                        "o": [
                          [37.358, -2.525],
                          [22.405, -2.026],
                          [-24.641, -46.063],
                          [26, 0],
                          [-15, -44],
                          [22.788, 7.792]
                        ],
                        "v": [
                          [-357.742, 279.773],
                          [-300.405, 288.026],
                          [-385.637, 179.825],
                          [-446, 377],
                          [-431, 378],
                          [-410.427, 303.506]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 130,
                    "s": [
                      {
                        "i": [
                          [-23.732, -8.264],
                          [-38.761, -12.518],
                          [46.893, 20.812],
                          [-14.368, -119.763],
                          [-30, 0],
                          [-30.241, -13.975]
                        ],
                        "o": [
                          [32.534, 11.33],
                          [19.696, 6.361],
                          [-18.842, -56.985],
                          [-2.5, 31],
                          [-15, -44],
                          [21.509, 10.429]
                        ],
                        "v": [
                          [-381.839, 270.473],
                          [-355.278, 301.128],
                          [-368.297, 206.865],
                          [-465.5, 289],
                          [-432, 360],
                          [-421.259, 267.975]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 133,
                    "s": [
                      {
                        "i": [
                          [-6.639, -26.494],
                          [-1.97, -11.201],
                          [-20.038, 44.439],
                          [54.858, -31.378],
                          [-24.969, -16.63],
                          [-28.398, 1.928]
                        ],
                        "o": [
                          [9.101, 36.32],
                          [1.835, 10.436],
                          [28.423, -63.036],
                          [-60.17, 34.417],
                          [11.907, -44.936],
                          [25.282, -1.716]
                        ],
                        "v": [
                          [-404.887, 258.195],
                          [-444.463, 302.792],
                          [-372.854, 276.807],
                          [-459.24, 177.329],
                          [-487.2, 300.484],
                          [-433.474, 214.247]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 136,
                    "s": [
                      {
                        "i": [
                          [4.786, -22.596],
                          [5.507, 2.027],
                          [-23.091, 30.592],
                          [50.951, -5.492],
                          [-17.025, -22.611],
                          [-25.881, -7.164]
                        ],
                        "o": [
                          [-7.713, 32.306],
                          [0.41, 11.346],
                          [36.14, -44.145],
                          [-42.352, 11.891],
                          [18.897, -43.575],
                          [23.041, 6.378]
                        ],
                        "v": [
                          [-408.049, 259.447],
                          [-461.724, 278.769],
                          [-397.355, 280.531],
                          [-438.642, 174.968],
                          [-494.543, 259.349],
                          [-425.752, 205.387]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 139,
                    "s": [
                      {
                        "i": [
                          [16.21, -18.698],
                          [12.984, 15.255],
                          [-26.144, 16.745],
                          [47.045, 20.394],
                          [8.887, -22.215],
                          [-23.364, -16.257]
                        ],
                        "o": [
                          [-24.527, 28.292],
                          [-1.016, 12.255],
                          [43.856, -25.255],
                          [-24.534, -10.635],
                          [23.887, -17.215],
                          [20.8, 14.473]
                        ],
                        "v": [
                          [-411.21, 260.698],
                          [-478.984, 254.745],
                          [-421.856, 284.255],
                          [-416.045, 181.606],
                          [-477.887, 196.215],
                          [-418.03, 196.527]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 142,
                    "s": [
                      {
                        "i": [
                          [24.015, -5.971],
                          [8.393, 18.189],
                          [-29.689, 9.082],
                          [25.879, 32.153],
                          [20.697, -5.302],
                          [-9.862, -11.23]
                        ],
                        "o": [
                          [-18.64, 4.635],
                          [1.023, 10.628],
                          [37.959, -13.182],
                          [-12.794, -15.895],
                          [22.697, -1.302],
                          [23.754, 27.049]
                        ],
                        "v": [
                          [-431.36, 270.365],
                          [-469.023, 255.372],
                          [-429.459, 275.682],
                          [-401.706, 193.895],
                          [-451.197, 185.302],
                          [-407.638, 199.73]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  },
                  {
                    "t": 145,
                    "s": [
                      {
                        "i": [
                          [23.607, 7.423],
                          [-2.319, 19.898],
                          [-20.979, -6.02],
                          [-4.297, 30.308],
                          [14.458, 15.441],
                          [3.092, -14.622]
                        ],
                        "o": [
                          [-18.323, -5.762],
                          [-3.304, 7.937],
                          [19.021, 7.48],
                          [2.864, -20.202],
                          [11.958, 16.941],
                          [-5.694, 26.921]
                        ],
                        "v": [
                          [-469.871, 272.492],
                          [-494.196, 240.063],
                          [-470.021, 273.52],
                          [-416.203, 245.692],
                          [-426.458, 208.559],
                          [-418.306, 246.079]
                        ],
                        "c": true
                      }
                    ],
                    "h": 1
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [0.898039275525, 0.725490196078, 0.384313755409, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 102,
      "op": 147,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 2,
      "nm": "door.png",
      "cl": "png",
      "parent": 1,
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.456], "y": [1] }, "o": { "x": [0.358], "y": [0] }, "t": 101, "s": [0] },
            { "i": { "x": [0.693], "y": [0.355] }, "o": { "x": [0.363], "y": [0] }, "t": 110, "s": [-24.186] },
            { "i": { "x": [0.68], "y": [0.922] }, "o": { "x": [0.348], "y": [0.053] }, "t": 112, "s": [-23.114] },
            { "i": { "x": [0.678], "y": [0.935] }, "o": { "x": [0.346], "y": [0.071] }, "t": 113, "s": [-15.649] },
            { "i": { "x": [0.675], "y": [0.929] }, "o": { "x": [0.343], "y": [0.089] }, "t": 114, "s": [-6.846] },
            { "t": 115, "s": [0] }
          ],
          "ix": 10
        },
        "p": {
          "s": true,
          "x": {
            "a": 1,
            "k": [
              { "i": { "x": [0.637], "y": [0.871] }, "o": { "x": [0.314], "y": [0] }, "t": 101, "s": [606.358] },
              { "i": { "x": [0.412], "y": [1] }, "o": { "x": [0.156], "y": [0.27] }, "t": 115, "s": [975.207] },
              { "t": 131, "s": [1178.59] }
            ],
            "ix": 3
          },
          "y": {
            "a": 1,
            "k": [
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [365.29] },
              { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.333], "y": [0] }, "t": 108, "s": [194.29] },
              { "t": 115, "s": [365.29] }
            ],
            "ix": 4
          }
        },
        "a": { "a": 0, "k": [248, 731, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [96.659, 96.659, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 2,
      "nm": "eyelid masked.png",
      "cl": "png",
      "parent": 10,
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 4.315, "ix": 10 },
        "p": { "a": 0, "k": [250.515, 222.748, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [34.5, 36.5, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100.098, 99.348, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 136,
      "op": 139,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 2,
      "nm": "hand L.png",
      "cl": "png",
      "parent": 14,
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.451], "y": [1] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [4.6] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 109, "s": [-19.4] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 116, "s": [13.776] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 123, "s": [-0.56] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 150, "s": [-0.56] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 154, "s": [-19.56] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 162, "s": [30.951] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 181, "s": [30.951] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 189, "s": [-32.049] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 202, "s": [16.219] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 237, "s": [-9.049] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 262, "s": [-0.56] },
            { "i": { "x": [0.738], "y": [0.617] }, "o": { "x": [0.333], "y": [0] }, "t": 293, "s": [-19.56] },
            { "t": 307, "s": [-9.016] }
          ],
          "ix": 10
        },
        "p": { "a": 0, "k": [155.086, 166.244, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [265, 145, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [97.352, 102.72, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 2,
      "nm": "Stick.png",
      "cl": "png",
      "parent": 8,
      "refId": "image_3",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [-0.439] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 109, "s": [28.561] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 117, "s": [-20.439] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 122, "s": [6.561] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 127, "s": [-3.652] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 131, "s": [1.588] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 135, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 154, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 160, "s": [-50] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 182, "s": [2] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 207, "s": [-20] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 240, "s": [4] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 278, "s": [-12.073] },
            { "t": 307, "s": [-2.049] }
          ],
          "ix": 10
        },
        "p": { "a": 0, "k": [83.413, 110.489, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [495.5, 13.5, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 2,
      "nm": "head.png",
      "cl": "png",
      "parent": 14,
      "refId": "image_4",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.383], "y": [1] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [-3.386] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.585], "y": [0] }, "t": 109, "s": [-15.386] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 115, "s": [-3.386] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 146, "s": [-3.386] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 172, "s": [-12.386] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 182, "s": [-3.386] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 189, "s": [-12.386] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 196, "s": [-3.386] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 253.423, "s": [3.458] },
            { "i": { "x": [0.667], "y": [0.433] }, "o": { "x": [0.333], "y": [0] }, "t": 292.714, "s": [-7.343] },
            { "t": 307.8349609375, "s": [-3.401] }
          ],
          "ix": 10
        },
        "p": {
          "s": true,
          "x": {
            "a": 1,
            "k": [
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 182, "s": [98.611] },
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 189, "s": [139.794] },
              { "t": 196, "s": [98.611] }
            ],
            "ix": 3
          },
          "y": {
            "a": 1,
            "k": [
              { "i": { "x": [0.487], "y": [1] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [47.119] },
              { "i": { "x": [0.478], "y": [1] }, "o": { "x": [0.548], "y": [0] }, "t": 109, "s": [85.103] },
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 115, "s": [47.119] },
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 182, "s": [47.119] },
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 189, "s": [110.425] },
              { "t": 196, "s": [47.119] }
            ],
            "ix": 4
          }
        },
        "a": { "a": 0, "k": [388, 402.5, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100.012, 99.988, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 11,
      "ty": 2,
      "nm": "hand R.png",
      "cl": "png",
      "parent": 14,
      "refId": "image_5",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0.167] }, "t": 98, "s": [-69.723] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 101, "s": [20.641] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 106, "s": [-29.084] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 112, "s": [12.443] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 119, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 150, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 156, "s": [33] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 159, "s": [-86.217] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 182, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 185, "s": [-40.119] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 190, "s": [3.402] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 196, "s": [-18.023] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 203, "s": [3.402] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 209, "s": [-5.96] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 222, "s": [-0.96] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 256, "s": [-12.96] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 283, "s": [3.402] },
            { "t": 307, "s": [-7.868] }
          ],
          "ix": 10
        },
        "p": { "a": 0, "k": [-6.561, 80.972, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [294, 192.5, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100.63, 99.374, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 12,
      "ty": 2,
      "nm": "leg L.png",
      "cl": "png",
      "parent": 17,
      "refId": "image_6",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.611], "y": [0.758] }, "o": { "x": [0.324], "y": [0.091] }, "t": 102, "s": [-4.862] },
            { "i": { "x": [0.413], "y": [1] }, "o": { "x": [0.162], "y": [0.727] }, "t": 103, "s": [10.471] },
            { "i": { "x": [0.578], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 108, "s": [21.138] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 113, "s": [-4.862] },
            { "i": { "x": [0.611], "y": [0.758] }, "o": { "x": [0.324], "y": [0.091] }, "t": 150, "s": [-4.862] },
            { "i": { "x": [0.413], "y": [1] }, "o": { "x": [0.162], "y": [0.727] }, "t": 151, "s": [10.471] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.355], "y": [0] }, "t": 156, "s": [21.138] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 158, "s": [-30.938] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 173, "s": [-4.862] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 179, "s": [-34.862] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 186, "s": [23] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 194, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 222, "s": [-12] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 253, "s": [2.75] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 281, "s": [-7.5] },
            { "t": 307, "s": [0] }
          ],
          "ix": 10
        },
        "p": { "a": 0, "k": [43.578, -52.639, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [119, 29.5, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [34.05, 38.388, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 13,
      "ty": 2,
      "nm": "leg R.png",
      "cl": "png",
      "parent": 17,
      "refId": "image_7",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.569], "y": [0.539] }, "o": { "x": [0.203], "y": [0.101] }, "t": 100, "s": [0] },
            { "i": { "x": [0.658], "y": [0.814] }, "o": { "x": [0.312], "y": [0.388] }, "t": 101, "s": [-46.3] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.351], "y": [1.524] }, "t": 102, "s": [-86.15] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 103, "s": [-91.15] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 104, "s": [-94.15] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 105, "s": [-95.15] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 106, "s": [-95.6] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 107, "s": [-95.8] },
            { "i": { "x": [0.687], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 108, "s": [-95.8] },
            { "i": { "x": [0.66], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 113, "s": [-86.8] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.351], "y": [1.524] }, "t": 150, "s": [-86.15] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 151, "s": [-91.15] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 152, "s": [-94.15] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 153, "s": [-95.15] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 154, "s": [-95.6] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 155, "s": [-95.8] },
            { "i": { "x": [0.585], "y": [0.92] }, "o": { "x": [0.555], "y": [0] }, "t": 156, "s": [-95.8] },
            { "i": { "x": [0.687], "y": [1] }, "o": { "x": [0.265], "y": [6.687] }, "t": 160, "s": [-12.946] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.351], "y": [1.524] }, "t": 182, "s": [-9.478] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 183, "s": [-14.478] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 184, "s": [-17.478] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 185, "s": [-18.478] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 186, "s": [-18.928] },
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 187, "s": [-19.128] },
            { "i": { "x": [0.57], "y": [0.463] }, "o": { "x": [0.175], "y": [0] }, "t": 188, "s": [-19.128] },
            { "i": { "x": [0.67], "y": [-1.607] }, "o": { "x": [0.33], "y": [2.607] }, "t": 190, "s": [-11.851] },
            { "i": { "x": [0.696], "y": [12.488] }, "o": { "x": [0.354], "y": [-9.123] }, "t": 191, "s": [-11.277] },
            { "t": 192, "s": [-11.453] }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 181,
              "s": [79.295, -30.352, 0],
              "to": [0, -0.349, 0],
              "ti": [0, 1.793, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 182,
              "s": [79.295, -32.444, 0],
              "to": [0, -1.793, 0],
              "ti": [0, 2.042, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 183,
              "s": [79.295, -41.11, 0],
              "to": [0, -2.042, 0],
              "ti": [0, 0.946, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 184,
              "s": [79.295, -44.696, 0],
              "to": [0, -0.946, 0],
              "ti": [0, 0.498, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 185,
              "s": [79.295, -46.788, 0],
              "to": [0, -0.498, 0],
              "ti": [0, 0.249, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 186,
              "s": [79.295, -47.685, 0],
              "to": [0, -0.249, 0],
              "ti": [0, 0.05, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 187,
              "s": [79.295, -48.282, 0],
              "to": [0, -0.05, 0],
              "ti": [0, -0.548, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 188,
              "s": [79.295, -47.984, 0],
              "to": [0, 0.548, 0],
              "ti": [0, -1.195, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 189,
              "s": [79.295, -44.995, 0],
              "to": [0, 1.195, 0],
              "ti": [0, -1.395, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 190,
              "s": [79.295, -40.811, 0],
              "to": [0, 1.395, 0],
              "ti": [0, -1.345, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 191,
              "s": [79.295, -36.628, 0],
              "to": [0, 1.345, 0],
              "ti": [0, -0.897, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 192,
              "s": [79.295, -32.743, 0],
              "to": [0, 0.897, 0],
              "ti": [0, -0.249, 0]
            },
            { "t": 193, "s": [79.295, -31.248, 0] }
          ],
          "ix": 2,
          "l": 2
        },
        "a": { "a": 0, "k": [99, 8.5, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [34.016, 38.426, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 14,
      "ty": 2,
      "nm": "Chest.png",
      "cl": "png",
      "parent": 17,
      "refId": "image_8",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 182, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 188, "s": [9] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 193, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 222, "s": [-3.004] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 253, "s": [0.998] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 281, "s": [-1.825] },
            { "t": 307, "s": [0] }
          ],
          "ix": 10
        },
        "p": {
          "s": true,
          "x": {
            "a": 1,
            "k": [
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 182, "s": [70.304] },
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 188, "s": [80.886] },
              { "t": 193, "s": [70.304] }
            ],
            "ix": 3
          },
          "y": {
            "a": 1,
            "k": [
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 182, "s": [-48.021] },
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 188, "s": [-42.044] },
              { "t": 193, "s": [-48.021] }
            ],
            "ix": 4
          }
        },
        "a": { "a": 0, "k": [50.006, 294.989, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [34.016, 38.426, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 15,
      "ty": 2,
      "nm": "tail.png",
      "cl": "png",
      "parent": 14,
      "refId": "image_9",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0.167] }, "t": 97, "s": [-14.167] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 101, "s": [-36.167] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 108, "s": [46.833] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 116, "s": [-20.496] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 123, "s": [-5.167] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 156, "s": [-5.167] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 161, "s": [74.833] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 182, "s": [-47.854] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 190, "s": [34.274] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 198, "s": [-36.167] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 205, "s": [3.708] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 212, "s": [-8.125] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 245, "s": [6.833] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 277, "s": [-5.167] },
            { "t": 307, "s": [0.355] }
          ],
          "ix": 10
        },
        "p": { "a": 0, "k": [72.92, 273.331, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [2.009, 189.826, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [101.663, 111.464, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 16,
      "ty": 3,
      "nm": "Null 2",
      "parent": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 0, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 156, "s": [0] },
            { "t": 182, "s": [360] }
          ],
          "ix": 10
        },
        "p": { "a": 0, "k": [608.5, -398, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 17,
      "ty": 3,
      "nm": "Null 1",
      "parent": 16,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 0, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "s": true,
          "x": {
            "a": 1,
            "k": [
              { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.617], "y": [0] }, "t": 92, "s": [-152] },
              { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 102, "s": [-368] },
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 112, "s": [-368] },
              { "i": { "x": [0.564], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 156, "s": [-368] },
              { "t": 182, "s": [-54.095] }
            ],
            "ix": 3
          },
          "y": {
            "a": 1,
            "k": [
              { "i": { "x": [0.854], "y": [0.972] }, "o": { "x": [0.401], "y": [0] }, "t": 92, "s": [-266] },
              { "i": { "x": [0.47], "y": [1] }, "o": { "x": [0.17], "y": [0.78] }, "t": 102, "s": [761] },
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 108, "s": [787] },
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 113, "s": [761] },
              { "i": { "x": [0.517], "y": [0.686] }, "o": { "x": [0.145], "y": [0] }, "t": 150, "s": [761] },
              { "i": { "x": [0.643], "y": [0.827] }, "o": { "x": [0.3], "y": [0.245] }, "t": 151, "s": [774.593] },
              { "i": { "x": [0.669], "y": [1.005] }, "o": { "x": [0.328], "y": [0.417] }, "t": 152, "s": [785.419] },
              { "i": { "x": [0.694], "y": [1] }, "o": { "x": [0.352], "y": [-0.009] }, "t": 153, "s": [789.545] },
              { "i": { "x": [0.694], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 154, "s": [792] },
              { "i": { "x": [0.45], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 156, "s": [792] },
              { "i": { "x": [0.944], "y": [0.362] }, "o": { "x": [0.602], "y": [0] }, "t": 170, "s": [261.502] },
              { "i": { "x": [0.47], "y": [1] }, "o": { "x": [0.17], "y": [0.736] }, "t": 182, "s": [622.85] },
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 187.664, "s": [648.85] },
              { "t": 192.6640625, "s": [622.85] }
            ],
            "ix": 4
          }
        },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [189, 167.311, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 18,
      "ty": 2,
      "nm": "lamp - 01.png",
      "cl": "png",
      "parent": 1,
      "refId": "image_10",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 10, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 19, "s": [-3.136] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 30, "s": [1.451] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 38, "s": [-0.958] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 44, "s": [0.54] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 51, "s": [-0.238] },
            { "i": { "x": [0.662], "y": [1] }, "o": { "x": [0.296], "y": [0] }, "t": 57, "s": [0] },
            { "i": { "x": [0.693], "y": [1] }, "o": { "x": [0.353], "y": [0] }, "t": 80, "s": [0] },
            { "i": { "x": [0.76], "y": [0.945] }, "o": { "x": [0.41], "y": [0] }, "t": 89, "s": [-8.767] },
            { "i": { "x": [0.715], "y": [0.518] }, "o": { "x": [0.358], "y": [-0.094] }, "t": 99, "s": [1.862] },
            { "i": { "x": [0.585], "y": [1] }, "o": { "x": [0.285], "y": [0.436] }, "t": 101, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 122, "s": [-159.929] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 149, "s": [33.961] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 169, "s": [-21.473] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 189.877, "s": [33.961] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 210.413, "s": [-21.473] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 230, "s": [10.593] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 244, "s": [-6.565] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 257, "s": [4.073] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 267, "s": [-2.576] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 277, "s": [1.295] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 284, "s": [-0.935] },
            { "t": 291, "s": [0] }
          ],
          "ix": 10
        },
        "p": {
          "s": true,
          "x": {
            "a": 1,
            "k": [
              { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 0, "s": [780.5] },
              { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [780.5] },
              { "t": 131, "s": [-402.96] }
            ],
            "ix": 3
          },
          "y": {
            "a": 1,
            "k": [
              { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [-590] },
              { "t": 131, "s": [-728.704] }
            ],
            "ix": 4
          }
        },
        "a": { "a": 0, "k": [88.5, 3, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 19,
      "ty": 2,
      "nm": "lamp - 02.png",
      "cl": "png",
      "parent": 1,
      "refId": "image_11",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 9, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 19, "s": [9.167] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 29, "s": [-5.042] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 37, "s": [3.198] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 44, "s": [-1.394] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 51, "s": [1.135] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 57, "s": [-0.542] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 64, "s": [0.414] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 71, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 79, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 89, "s": [9.167] },
            { "i": { "x": [0.734], "y": [-0.16] }, "o": { "x": [0.333], "y": [0] }, "t": 97, "s": [-5.042] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.418], "y": [0] }, "t": 101, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 111, "s": [90.076] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 131, "s": [-47.449] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 151, "s": [34.807] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 171, "s": [-19.322] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 191, "s": [14.096] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 210, "s": [-6.568] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 223, "s": [3.753] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 233, "s": [-1.912] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 243, "s": [0.972] },
            { "t": 251, "s": [0.269] }
          ],
          "ix": 10
        },
        "p": {
          "s": true,
          "x": {
            "a": 1,
            "k": [
              { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 0, "s": [-51.5] },
              { "i": { "x": [0.621], "y": [1] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [-51.5] },
              { "t": 117, "s": [-365.5] }
            ],
            "ix": 3
          },
          "y": {
            "a": 1,
            "k": [
              { "i": { "x": [0.621], "y": [1] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [-600] },
              { "t": 117, "s": [-376] }
            ],
            "ix": 4
          }
        },
        "a": { "a": 0, "k": [89, 0, 0], "ix": 1, "l": 2 },
        "s": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.621, 0.621, 0.667], "y": [1, 1, 1] },
              "o": { "x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0] },
              "t": 101,
              "s": [100, 100, 100]
            },
            { "t": 117, "s": [79, 79, 100] }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 20,
      "ty": 2,
      "nm": "door.png",
      "cl": "png",
      "parent": 1,
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [0] },
            { "t": 108, "s": [-90] }
          ],
          "ix": 10
        },
        "p": {
          "s": true,
          "x": {
            "a": 1,
            "k": [
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 0, "s": [128.642] },
              { "i": { "x": [0.336], "y": [1] }, "o": { "x": [0.131], "y": [0.075] }, "t": 101, "s": [128.642] },
              { "t": 133, "s": [-462.768] }
            ],
            "ix": 3
          },
          "y": {
            "a": 1,
            "k": [
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [365.29] },
              { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.333], "y": [0] }, "t": 105, "s": [279.247] },
              { "t": 108, "s": [365.29] }
            ],
            "ix": 4
          }
        },
        "a": { "a": 0, "k": [0, 731, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [96.659, 96.659, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 308,
      "st": 90,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 21,
      "ty": 2,
      "nm": "shadow door.png",
      "cl": "png",
      "parent": 1,
      "refId": "image_12",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [100] },
            { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 104, "s": [40] },
            { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 106, "s": [20] },
            { "t": 108, "s": [100] }
          ],
          "ix": 11
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "s": true,
          "x": {
            "a": 1,
            "k": [
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 0, "s": [251.306] },
              { "i": { "x": [0.536], "y": [0.84] }, "o": { "x": [0.241], "y": [0.025] }, "t": 101, "s": [251.306] },
              { "i": { "x": [0.432], "y": [1] }, "o": { "x": [0.194], "y": [0.452] }, "t": 108, "s": [-447.216] },
              { "t": 133, "s": [-816.216] }
            ],
            "ix": 3
          },
          "y": { "a": 0, "k": 365.5, "ix": 4 }
        },
        "a": { "a": 0, "k": [428.5, 18.5, 0], "ix": 1, "l": 2 },
        "s": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833] },
              "o": { "x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167] },
              "t": 101,
              "s": [34.189, 40.541, 100]
            },
            { "t": 108, "s": [90.198, 40.541, 100] }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 458,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 22,
      "ty": 2,
      "nm": "shadow door.png",
      "cl": "png",
      "parent": 1,
      "refId": "image_12",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 101, "s": [100] },
            { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 104, "s": [40] },
            { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 106, "s": [20] },
            { "t": 116, "s": [100] }
          ],
          "ix": 11
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "s": true,
          "x": {
            "a": 1,
            "k": [
              { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 0, "s": [485.837] },
              { "i": { "x": [0.637], "y": [0.871] }, "o": { "x": [0.314], "y": [0] }, "t": 101, "s": [485.837] },
              { "i": { "x": [0.409], "y": [1] }, "o": { "x": [0.156], "y": [0.271] }, "t": 115, "s": [854.687] },
              { "t": 131, "s": [1057.5] }
            ],
            "ix": 3
          },
          "y": { "a": 0, "k": 365.5, "ix": 4 }
        },
        "a": { "a": 0, "k": [428.5, 18.5, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [34.189, 40.541, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 458,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 23,
      "ty": 2,
      "nm": "shadow tiger.png",
      "cl": "png",
      "parent": 1,
      "refId": "image_13",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            { "i": { "x": [0.833], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 98, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 102, "s": [59.373] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 157, "s": [59.373] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 160, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 170, "s": [0] },
            { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 178, "s": [0] },
            { "t": 182, "s": [59.373] }
          ],
          "ix": 11
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "s": true,
          "x": {
            "a": 1,
            "k": [
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 0, "s": [618.407] },
              { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.617], "y": [0] }, "t": 92, "s": [618.407] },
              { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 102, "s": [402.407] },
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 112, "s": [402.407] },
              { "i": { "x": [0.564], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 156, "s": [402.407] },
              { "t": 182, "s": [716.311] }
            ],
            "ix": 3
          },
          "y": {
            "a": 1,
            "k": [
              { "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 157, "s": [380] },
              { "t": 182, "s": [355] }
            ],
            "ix": 4
          }
        },
        "a": { "a": 0, "k": [555, 18, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [58.198, 58.198, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 458,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": [
    { "tm": 11, "cm": "1", "dr": 0 },
    { "tm": 182, "cm": "2", "dr": 0 }
  ]
}
