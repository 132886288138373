import React, { useState } from "react";
import { Link } from "react-router-dom";

import TigerBack from "../assets/tiger-back.svg";
import TigerHover from "../assets/tiger-hover2.png";
import { ASSETS_URL } from "../constants";

const Card = ({ nft, index, deleyTime }) => {
  const [isFlipped, setFlipped] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const flipCard = async (time) => {
    await new Promise((res) => setTimeout(res, time));
    setFlipped(true);
  };

  const imageURL = ASSETS_URL + nft.metadata.image.slice(7) + "_small.jpg";

  return (
    <>
      {!isFlipped && <img src={TigerBack} alt="tiger-back" className="card" />}
      <div className="flip-card-container">
        <div className={isFlipped ? "flip-card flip-card--flipped" : "flip-card"}>
          <div className="flip-card__front">
            <img src={TigerBack} alt="tiger-back" className="card" key={index} />
          </div>
          <div
            className="flip-card__back"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered && (
              <div className="hover">
                <Link to={`/details/${nft.id}`} key={index}>
                  <img src={TigerHover} className="card" alt="hover" />
                </Link>
              </div>
            )}
            <img src={`${imageURL}`} className="card" onLoad={() => flipCard(deleyTime)} alt="card" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
