import img_0 from "../assets/anim-intro-loop-desktop/img_0.png";
import img_1 from "../assets/anim-intro-loop-desktop/img_1.png";
import img_2 from "../assets/anim-intro-loop-desktop/img_2.png";
import img_3 from "../assets/anim-intro-loop-desktop/img_3.png";
import img_4 from "../assets/anim-intro-loop-desktop/img_4.png";
import img_5 from "../assets/anim-intro-loop-desktop/img_5.png";
import img_6 from "../assets/anim-intro-loop-desktop/img_6.png";
import img_7 from "../assets/anim-intro-loop-desktop/img_7.png";
import img_8 from "../assets/anim-intro-loop-desktop/img_8.png";
import img_9 from "../assets/anim-intro-loop-desktop/img_9.png";
import img_10 from "../assets/anim-intro-loop-desktop/img_10.png";
import img_11 from "../assets/anim-intro-loop-desktop/img_11.png";
import img_12 from "../assets/anim-intro-loop-desktop/img_12.png";
import img_13 from "../assets/anim-intro-loop-desktop/img_13.png";

export const animIntroLoop = {
  v: "5.7.8",
  fr: 30,
  ip: 0,
  op: 228,
  w: 3100,
  h: 852,
  nm: "CT - motion - HomePage - introLoop - DESKTOP",
  ddd: 0,
  assets: [
    { id: "image_0", w: 69, h: 73, u: "", p: img_0, e: 0 },
    { id: "image_1", w: 248, h: 731, u: "", p: img_1, e: 0 },
    { id: "image_2", w: 266, h: 226, u: "", p: img_2, e: 0 },
    { id: "image_3", w: 991, h: 27, u: "", p: img_3, e: 0 },
    { id: "image_4", w: 712, h: 441, u: "", p: img_4, e: 0 },
    { id: "image_5", w: 280, h: 217, u: "", p: img_5, e: 0 },
    { id: "image_6", w: 142, h: 199, u: "", p: img_6, e: 0 },
    { id: "image_7", w: 126, h: 305, u: "", p: img_7, e: 0 },
    { id: "image_8", w: 240, h: 310, u: "", p: img_8, e: 0 },
    { id: "image_9", w: 381, h: 216, u: "", p: img_9, e: 0 },
    { id: "image_10", w: 177, h: 626, u: "", p: img_10, e: 0 },
    { id: "image_11", w: 178, h: 641, u: "", p: img_11, e: 0 },
    { id: "image_12", w: 857, h: 37, u: "", p: img_12, e: 0 },
    { id: "image_13", w: 1110, h: 36, u: "", p: img_13, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "Null 12",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1183.875, 426, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 458,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 12,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 4.315, ix: 10 },
        p: { a: 0, k: [408.772, 214.962, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [108.031, 101.494, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 163,
      op: 166,
      st: 117,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 12,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 4.315, ix: 10 },
        p: { a: 0, k: [250.515, 222.748, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.098, 99.348, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 163,
      op: 166,
      st: 117,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 12,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 4.315, ix: 10 },
        p: { a: 0, k: [408.772, 214.962, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [108.031, 101.494, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 155,
      op: 158,
      st: 109,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 12,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 4.315, ix: 10 },
        p: { a: 0, k: [250.515, 222.748, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.098, 99.348, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 155,
      op: 158,
      st: 109,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 12,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 4.315, ix: 10 },
        p: { a: 0, k: [408.772, 214.962, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [108.031, 101.494, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 47,
      op: 50,
      st: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 12,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 4.315, ix: 10 },
        p: { a: 0, k: [250.515, 222.748, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.098, 99.348, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 47,
      op: 50,
      st: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: "door.png",
      cl: "png",
      parent: 1,
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.456], y: [1] }, o: { x: [0.358], y: [0] }, t: -206, s: [0] },
            { i: { x: [0.693], y: [0.355] }, o: { x: [0.363], y: [0] }, t: -197, s: [-24.186] },
            { i: { x: [0.68], y: [0.922] }, o: { x: [0.348], y: [0.053] }, t: -195, s: [-23.114] },
            { i: { x: [0.678], y: [0.935] }, o: { x: [0.346], y: [0.071] }, t: -194, s: [-15.649] },
            { i: { x: [0.675], y: [0.929] }, o: { x: [0.343], y: [0.089] }, t: -193, s: [-6.846] },
            { t: -192, s: [0] },
          ],
          ix: 10,
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.637], y: [0.871] }, o: { x: [0.314], y: [0] }, t: -206, s: [606.358] },
              { i: { x: [0.412], y: [1] }, o: { x: [0.156], y: [0.27] }, t: -192, s: [975.207] },
              { t: -176, s: [1178.59] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -206, s: [365.29] },
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: -199, s: [194.29] },
              { t: -192, s: [365.29] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [248, 731, 0], ix: 1, l: 2 },
        s: { a: 0, k: [96.659, 96.659, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 275,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 12,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 4.315, ix: 10 },
        p: { a: 0, k: [250.515, 222.748, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.098, 99.348, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -171,
      op: -168,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: "hand L.png",
      cl: "png",
      parent: 16,
      refId: "image_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.451], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -249.794, s: [4.6] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -241.794, s: [-19.4] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -234.794, s: [13.776] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: -227.794, s: [-0.56] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -200.794, s: [-0.56] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -196.794, s: [-19.56] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -188.794, s: [30.951] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -169.794, s: [30.951] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -43.794, s: [-2.049] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -13.794, s: [-14.897] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 26.69, s: [6.951] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 66.013, s: [-9.049] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 95.206, s: [11.661] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 144.206, s: [-14.897] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 184.206, s: [-2.049] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 214.206, s: [-14.897] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 254.69, s: [6.951] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 294.013, s: [-9.049] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 323.206, s: [11.661] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 372.206, s: [-14.897] },
            { t: 412.2060546875, s: [-2.049] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [155.086, 166.244, 0], ix: 2, l: 2 },
        a: { a: 0, k: [265, 145, 0], ix: 1, l: 2 },
        s: { a: 0, k: [97.352, 102.72, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: "Stick.png",
      cl: "png",
      parent: 10,
      refId: "image_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -206, s: [-0.439] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -198, s: [28.561] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -190, s: [-20.439] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -185, s: [6.561] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -180, s: [-3.652] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -176, s: [1.588] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -172, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -153, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -147, s: [-50] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -125, s: [2] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [-2.049] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 32, s: [-9.924] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 70, s: [2.783] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 114, s: [-9.049] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 152, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 183, s: [-11.934] },
            { t: 228, s: [-2.049] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [83.413, 110.489, 0], ix: 2, l: 2 },
        a: { a: 0, k: [495.5, 13.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: "head.png",
      cl: "png",
      parent: 16,
      refId: "image_4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.383], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -292.251, s: [-3.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.585], y: [0] }, t: -284.251, s: [-15.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -278.251, s: [-3.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -211.251, s: [-3.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -204.251, s: [-12.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -197.251, s: [-3.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -86.251, s: [-3.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -54.412, s: [3.458] },
            { i: { x: [0.667], y: [0.433] }, o: { x: [0.333], y: [0] }, t: -15.121, s: [-7.343] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0.408] }, t: 0, s: [-3.401] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 28.749, s: [7.017] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 67.749, s: [-5.345] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 113.749, s: [4.89] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 141.749, s: [-3.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 173.588, s: [3.458] },
            { i: { x: [0.667], y: [0.433] }, o: { x: [0.333], y: [0] }, t: 212.879, s: [-7.343] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0.408] }, t: 228, s: [-3.401] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 256.749, s: [7.017] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 295.749, s: [-5.345] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 341.749, s: [4.89] },
            { t: 369.7490234375, s: [-3.386] },
          ],
          ix: 10,
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -125, s: [98.611] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -118, s: [139.794] },
              { t: -111, s: [98.611] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.487], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -206, s: [47.119] },
              { i: { x: [0.478], y: [1] }, o: { x: [0.548], y: [0] }, t: -198, s: [85.103] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -192, s: [47.119] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -125, s: [47.119] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -118, s: [110.425] },
              { t: -111, s: [47.119] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [388, 402.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.012, 99.988, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 2,
      nm: "hand R.png",
      cl: "png",
      parent: 16,
      refId: "image_5",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -227.721, s: [-69.723] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -224.721, s: [20.641] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -219.721, s: [-29.084] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -213.721, s: [12.443] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -206.721, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -175.721, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -169.721, s: [33] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -166.721, s: [-86.217] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -143.721, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -140.721, s: [-40.119] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -135.721, s: [3.402] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -129.721, s: [-18.023] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -122.721, s: [3.402] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -116.721, s: [-5.96] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -103.721, s: [-0.96] },
            { i: { x: [0.667], y: [0.527] }, o: { x: [0.333], y: [0] }, t: -18.721, s: [-0.96] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0.524] }, t: 0, s: [-7.868] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 15.279, s: [-12.96] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 56.279, s: [5.376] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 98.279, s: [-10.454] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 129.279, s: [3.402] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 181.279, s: [-12.96] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 209.279, s: [-0.96] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 243.279, s: [-12.96] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 284.279, s: [5.376] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 326.279, s: [-10.454] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 357.279, s: [3.402] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 409.279, s: [-12.96] },
            { t: 437.279296875, s: [-0.96] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-6.561, 80.972, 0], ix: 2, l: 2 },
        a: { a: 0, k: [294, 192.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.63, 99.374, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 2,
      nm: "leg L.png",
      cl: "png",
      parent: 19,
      refId: "image_6",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.611], y: [0.758] }, o: { x: [0.324], y: [0.091] }, t: -205, s: [-4.862] },
            { i: { x: [0.413], y: [1] }, o: { x: [0.162], y: [0.727] }, t: -204, s: [10.471] },
            { i: { x: [0.578], y: [1] }, o: { x: [0.333], y: [0] }, t: -199, s: [21.138] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: -194, s: [-4.862] },
            { i: { x: [0.611], y: [0.758] }, o: { x: [0.324], y: [0.091] }, t: -157, s: [-4.862] },
            { i: { x: [0.413], y: [1] }, o: { x: [0.162], y: [0.727] }, t: -156, s: [10.471] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.355], y: [0] }, t: -151, s: [21.138] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -149, s: [-30.938] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -134, s: [-4.862] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -128, s: [-34.862] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -121, s: [23] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -113, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -85, s: [-12] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -54, s: [2.75] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -26, s: [-7.5] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 28, s: [-12] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 59, s: [2.75] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 87, s: [-7.5] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 113, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 141, s: [-12] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 172, s: [2.75] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 201, s: [-9.768] },
            { t: 228, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [43.578, -52.639, 0], ix: 2, l: 2 },
        a: { a: 0, k: [119, 29.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [34.05, 38.388, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 2,
      nm: "leg R.png",
      cl: "png",
      parent: 19,
      refId: "image_7",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.569], y: [0.539] }, o: { x: [0.203], y: [0.101] }, t: -207, s: [0] },
            { i: { x: [0.658], y: [0.814] }, o: { x: [0.312], y: [0.388] }, t: -206, s: [-46.3] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.351], y: [1.524] }, t: -205, s: [-86.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -204, s: [-91.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -203, s: [-94.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -202, s: [-95.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -201, s: [-95.6] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -200, s: [-95.8] },
            { i: { x: [0.687], y: [1] }, o: { x: [0.167], y: [0] }, t: -199, s: [-95.8] },
            { i: { x: [0.66], y: [1] }, o: { x: [0.167], y: [0] }, t: -194, s: [-86.8] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.351], y: [1.524] }, t: -157, s: [-86.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -156, s: [-91.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -155, s: [-94.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -154, s: [-95.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -153, s: [-95.6] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -152, s: [-95.8] },
            { i: { x: [0.585], y: [0.92] }, o: { x: [0.555], y: [0] }, t: -151, s: [-95.8] },
            { i: { x: [0.687], y: [1] }, o: { x: [0.265], y: [6.687] }, t: -147, s: [-12.946] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.351], y: [1.524] }, t: -125, s: [-9.478] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -124, s: [-14.478] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -123, s: [-17.478] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -122, s: [-18.478] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -121, s: [-18.928] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -120, s: [-19.128] },
            { i: { x: [0.57], y: [0.463] }, o: { x: [0.175], y: [0] }, t: -119, s: [-19.128] },
            { i: { x: [0.67], y: [-1.607] }, o: { x: [0.33], y: [2.607] }, t: -117, s: [-11.851] },
            { i: { x: [0.696], y: [12.488] }, o: { x: [0.354], y: [-9.123] }, t: -116, s: [-11.277] },
            { t: -115, s: [-11.453] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -126,
              s: [79.295, -30.352, 0],
              to: [0, -0.349, 0],
              ti: [0, 1.793, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -125,
              s: [79.295, -32.444, 0],
              to: [0, -1.793, 0],
              ti: [0, 2.042, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -124,
              s: [79.295, -41.11, 0],
              to: [0, -2.042, 0],
              ti: [0, 0.946, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -123,
              s: [79.295, -44.696, 0],
              to: [0, -0.946, 0],
              ti: [0, 0.498, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -122,
              s: [79.295, -46.788, 0],
              to: [0, -0.498, 0],
              ti: [0, 0.249, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -121,
              s: [79.295, -47.685, 0],
              to: [0, -0.249, 0],
              ti: [0, 0.05, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -120,
              s: [79.295, -48.282, 0],
              to: [0, -0.05, 0],
              ti: [0, -0.548, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -119,
              s: [79.295, -47.984, 0],
              to: [0, 0.548, 0],
              ti: [0, -1.195, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -118,
              s: [79.295, -44.995, 0],
              to: [0, 1.195, 0],
              ti: [0, -1.395, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -117,
              s: [79.295, -40.811, 0],
              to: [0, 1.395, 0],
              ti: [0, -1.345, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -116,
              s: [79.295, -36.628, 0],
              to: [0, 1.345, 0],
              ti: [0, -0.897, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -115,
              s: [79.295, -32.743, 0],
              to: [0, 0.897, 0],
              ti: [0, -0.249, 0],
            },
            { t: -114, s: [79.295, -31.248, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [99, 8.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [34.016, 38.426, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 2,
      nm: "Chest.png",
      cl: "png",
      parent: 19,
      refId: "image_8",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -168.125, s: [-0.015] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -43.125, s: [-0.015] },
            { i: { x: [0.667], y: [0.727] }, o: { x: [0.333], y: [0] }, t: -14.125, s: [-3.019] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [1.557] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 16.875, s: [0.633] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 44.875, s: [-2.656] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 70.875, s: [1.464] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 99.875, s: [-1.839] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 130.875, s: [0.983] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 158.875, s: [-2.306] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 184.875, s: [-0.015] },
            { i: { x: [0.667], y: [0.727] }, o: { x: [0.333], y: [0] }, t: 213.875, s: [-3.019] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [1.557] }, t: 228, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 244.875, s: [0.633] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 272.875, s: [-2.656] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 298.875, s: [1.464] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 327.875, s: [-1.839] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 358.875, s: [0.983] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 386.875, s: [-2.306] },
            { t: 412.875, s: [-0.015] },
          ],
          ix: 10,
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -125, s: [70.304] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -119, s: [80.886] },
              { t: -114, s: [70.304] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -125, s: [-48.021] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -119, s: [-42.044] },
              { t: -114, s: [-48.021] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50.006, 294.989, 0], ix: 1, l: 2 },
        s: { a: 0, k: [34.016, 38.426, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 2,
      nm: "tail.png",
      cl: "png",
      parent: 16,
      refId: "image_9",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -265.495, s: [-14.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -261.495, s: [-36.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -254.495, s: [46.833] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -246.495, s: [-20.496] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -239.495, s: [-5.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -206.495, s: [-5.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -201.495, s: [74.833] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -180.495, s: [-47.854] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -55.495, s: [6.833] },
            { i: { x: [0.667], y: [0.516] }, o: { x: [0.333], y: [0] }, t: -25.495, s: [-12.811] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0.515] }, t: 0, s: [0.355] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 22.505, s: [11.273] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 55.505, s: [-5.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 105.505, s: [14.479] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 141.505, s: [-17.211] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 172.505, s: [6.833] },
            { i: { x: [0.667], y: [0.516] }, o: { x: [0.333], y: [0] }, t: 202.505, s: [-12.811] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0.515] }, t: 228, s: [0.355] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 250.505, s: [11.273] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 283.505, s: [-5.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 333.505, s: [14.479] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 369.505, s: [-17.211] },
            { t: 400.5048828125, s: [6.833] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [72.92, 273.331, 0], ix: 2, l: 2 },
        a: { a: 0, k: [2.009, 189.826, 0], ix: 1, l: 2 },
        s: { a: 0, k: [101.663, 111.464, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 3,
      nm: "Null 2",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -151, s: [0] },
            { t: -125, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [608.5, -398, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 3,
      nm: "Null 1",
      parent: 18,
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.617], y: [0] }, t: -215, s: [-152] },
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: -205, s: [-368] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -195, s: [-368] },
              { i: { x: [0.564], y: [1] }, o: { x: [0.333], y: [0] }, t: -151, s: [-368] },
              { t: -125, s: [-54.095] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.854], y: [0.972] }, o: { x: [0.401], y: [0] }, t: -215, s: [-266] },
              { i: { x: [0.47], y: [1] }, o: { x: [0.17], y: [0.78] }, t: -205, s: [761] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -199, s: [787] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -194, s: [761] },
              { i: { x: [0.517], y: [0.686] }, o: { x: [0.145], y: [0] }, t: -157, s: [761] },
              { i: { x: [0.643], y: [0.827] }, o: { x: [0.3], y: [0.245] }, t: -156, s: [774.593] },
              { i: { x: [0.669], y: [1.005] }, o: { x: [0.328], y: [0.417] }, t: -155, s: [785.419] },
              { i: { x: [0.694], y: [1] }, o: { x: [0.352], y: [-0.009] }, t: -154, s: [789.545] },
              { i: { x: [0.694], y: [1] }, o: { x: [0.167], y: [0] }, t: -153, s: [792] },
              { i: { x: [0.45], y: [1] }, o: { x: [0.167], y: [0] }, t: -151, s: [792] },
              { i: { x: [0.944], y: [0.362] }, o: { x: [0.602], y: [0] }, t: -137, s: [261.502] },
              { i: { x: [0.47], y: [1] }, o: { x: [0.17], y: [0.736] }, t: -125, s: [622.85] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -119.336, s: [648.85] },
              { t: -114.3359375, s: [622.85] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [189, 167.311, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 2,
      nm: "lamp - 01.png",
      cl: "png",
      parent: 1,
      refId: "image_10",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -297, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -288, s: [-3.136] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -277, s: [1.451] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -269, s: [-0.958] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -263, s: [0.54] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -256, s: [-0.238] },
            { i: { x: [0.662], y: [1] }, o: { x: [0.296], y: [0] }, t: -250, s: [0] },
            { i: { x: [0.693], y: [1] }, o: { x: [0.353], y: [0] }, t: -227, s: [0] },
            { i: { x: [0.76], y: [0.945] }, o: { x: [0.41], y: [0] }, t: -218, s: [-8.767] },
            { i: { x: [0.715], y: [0.518] }, o: { x: [0.358], y: [-0.094] }, t: -208, s: [1.862] },
            { i: { x: [0.585], y: [1] }, o: { x: [0.285], y: [0.436] }, t: -206, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -185, s: [-159.929] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -158, s: [33.961] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -138, s: [-21.473] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -117.123, s: [33.961] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -96.587, s: [-21.473] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -77, s: [10.593] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -63, s: [-6.565] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -50, s: [4.073] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -40, s: [-2.576] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -30, s: [1.295] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -23, s: [-0.935] },
            { t: -16, s: [0] },
          ],
          ix: 10,
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: -206, s: [780.5] },
              { t: -176, s: [-402.96] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: -206, s: [-590] },
              { t: -176, s: [-728.704] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [88.5, 3, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 21,
      ty: 2,
      nm: "lamp - 02.png",
      cl: "png",
      parent: 1,
      refId: "image_11",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -298, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -288, s: [9.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -278, s: [-5.042] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -270, s: [3.198] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -263, s: [-1.394] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -256, s: [1.135] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -250, s: [-0.542] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -243, s: [0.414] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -236, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -228, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -218, s: [9.167] },
            { i: { x: [0.734], y: [-0.16] }, o: { x: [0.333], y: [0] }, t: -210, s: [-5.042] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.418], y: [0] }, t: -206, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -196, s: [90.076] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -176, s: [-47.449] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -156, s: [34.807] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -136, s: [-19.322] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -116, s: [14.096] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -97, s: [-6.568] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -84, s: [3.753] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -74, s: [-1.912] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -64, s: [0.972] },
            { t: -56, s: [0.269] },
          ],
          ix: 10,
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.621], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -206, s: [-51.5] },
              { t: -190, s: [-365.5] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.621], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -206, s: [-600] },
              { t: -190, s: [-376] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [89, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.621, 0.621, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
              t: -206,
              s: [100, 100, 100],
            },
            { t: -190, s: [79, 79, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 22,
      ty: 2,
      nm: "door.png",
      cl: "png",
      parent: 1,
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -90, ix: 10 },
        p: { s: true, x: { a: 0, k: -462.768, ix: 3 }, y: { a: 0, k: 365.29, ix: 4 } },
        a: { a: 0, k: [0, 731, 0], ix: 1, l: 2 },
        s: { a: 0, k: [96.659, 96.659, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -307,
      op: 258,
      st: -217,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 23,
      ty: 2,
      nm: "shadow door.png",
      cl: "png",
      parent: 1,
      refId: "image_12",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1057.5, 365.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [428.5, 18.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [34.189, 40.541, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -121,
      op: 337,
      st: -121,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 24,
      ty: 2,
      nm: "shadow tiger.png",
      cl: "png",
      parent: 1,
      refId: "image_13",
      sr: 1,
      ks: {
        o: { a: 0, k: 59.373, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { s: true, x: { a: 0, k: 716.311, ix: 3 }, y: { a: 0, k: 355, ix: 4 } },
        a: { a: 0, k: [555, 18, 0], ix: 1, l: 2 },
        s: { a: 0, k: [58.198, 58.198, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -121,
      op: 337,
      st: -121,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 25,
      ty: 2,
      nm: "shadow door.png",
      cl: "png",
      parent: 1,
      refId: "image_12",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { s: true, x: { a: 0, k: -816.216, ix: 3 }, y: { a: 0, k: 365.5, ix: 4 } },
        a: { a: 0, k: [428.5, 18.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [90.198, 40.541, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: -181,
      op: 277,
      st: -181,
      bm: 0,
    },
  ],
  markers: [
    { tm: 11, cm: "1", dr: 0 },
    { tm: 121, cm: "2", dr: 0 },
    { tm: 349, cm: "3", dr: 0 },
  ],
};
