import React from "react";

import ErrorImg from "../assets/error.svg";
import Text from "../data/text.json";

import Wrapper from "./wrapper";

const Error = ({ error, width, getStatus }) => {
  return (
    <>
      <Wrapper
        width={width}
        content={
          <div className="popup__content popup__content--g20">
            <h3 className="h3">{Text.text42}</h3>
            <p>
              {Text.text43}
              {error}
            </p>
            <img src={ErrorImg} alt="error" className="clogo--signer" />
            <button onClick={getStatus} className="btn btn--redeem btn--w">
              {Text.text44}
            </button>
          </div>
        }
      />
    </>
  );
};

export default Error;
