import React from "react";
import { Link } from "react-router-dom";

import { ASSETS_URL } from "../constants";

const TigerImg = ({ tiger, isDetail, nextTigerId, prevTigerId }) => {
  //Cut url path
  const tigerImg = ASSETS_URL + tiger.metadata.image.slice(7) + ".jpg";

  return (
    <>
      {isDetail ? (
        <div className="tiger__img-wrapper">
          <Link to={`/details/${prevTigerId()}`} className="tiger__img-wrapper">
            <div className="tiger__chevron tiger__chevron--back"></div>
          </Link>
          <img src={tigerImg} alt="card" className="tiger__img" />
          <Link to={`/details/${nextTigerId()}`} className="tiger__img-wrapper">
            <div className="tiger__chevron"></div>
          </Link>
        </div>
      ) : (
        <img src={tigerImg} alt="card" className="tiger__img" />
      )}
    </>
  );
};

export default TigerImg;
