import React from "react";
import { useParams } from "react-router-dom";

import TigerDesc from "./tiger-desc";
import TigerImg from "./tiger-img";

const TigerDetails = ({ NFTs, signerAccountHash }) => {
  const { id } = useParams();

  if (!NFTs) return null;

  //Find selected tiger
  const tigerIndex = NFTs.findIndex((t) => t && t.id === id);
  const tiger = NFTs[tigerIndex];

  if (!tiger) return null;

  //Tiger owner hash
  const tigerOwnerHash = tiger.owner.slice(13);

  //Next tiger
  const nextTigerId = () => {
    const maybeNext = NFTs[tigerIndex + 1];
    return maybeNext ? maybeNext?.id : NFTs[0].id;
  };
  const prevTigerId = () => {
    const maybePrev = NFTs[tigerIndex - 1];
    return maybePrev ? maybePrev?.id : NFTs[NFTs.length - 1].id;
  };

  const infoOwn = () => {
    if (tigerOwnerHash === signerAccountHash) {
      return "You are the owner of the NFT";
    }
    if (signerAccountHash === null) {
      return "Please connect a Signer";
    }
    if (signerAccountHash !== tigerOwnerHash) {
      return "";
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="wrapper__bg">
          <div className="tiger">
            <TigerImg tiger={tiger} isDetail={true} nextTigerId={nextTigerId} prevTigerId={prevTigerId} />
            <TigerDesc
              tiger={tiger}
              tigerOwnerHash={tigerOwnerHash}
              info={<div className="nft-data__info">{infoOwn()}</div>}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TigerDetails;
