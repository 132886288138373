import React from "react";

import Lantern from "../assets/bg-img-lantern.svg";
import TigerDoor from "../assets/bg-img-tiger-door.svg";
import LanternMobile from "../assets/bg-img-lantern-mobile.svg";
import TigerDoorMobile from "../assets/bg-img-tiger-door-mobile.svg";

import { MOBILE_BREAKPOINT } from "../constants";

const Wrapper = ({ content, width }) => {
  return (
    <>
      <div className="popup">
        {width < MOBILE_BREAKPOINT ? (
          <div className="bg-img-container">
            <img src={LanternMobile} alt="lantern" className="img-lantern" />
            <img src={TigerDoorMobile} alt="bg-tiger-door" className="img-tiger-door" />
            <div className="overlay"></div>
          </div>
        ) : (
          <div className="bg-img-container">
            <img src={Lantern} alt="lantern" className="img-lantern" />
            <img src={TigerDoor} alt="bg-tiger-door" className="img-tiger-door" />
            <div className="overlay"></div>
          </div>
        )}
        {content}
      </div>
    </>
  );
};

export default Wrapper;
