import React from "react";


import Lantern from "../assets/bg-img-lantern.svg";
import TigerDoor from "../assets/bg-img-tiger-door.svg";
import LanternMobile from "../assets/bg-img-lantern-mobile.svg";
import TigerDoorMobile from "../assets/bg-img-tiger-door-mobile.svg";

import { MOBILE_BREAKPOINT } from "../constants";

const PrivItem = ({ title, paragraph1 }) => {
  return (
    <>
      <h4 className="h-priv">{title}</h4>
      <div className="p-priv">{paragraph1}</div>
    </>
  );
};

const PrivItemDouble = ({ title, paragraph1, paragraph2 }) => {
  return (
    <>
      <h4 className="h-priv">{title}</h4>
      <div className="p-priv">
        {paragraph1}
        <br />
        <br />
        {paragraph2}
      </div>
    </>
  );
};
const PrivItemTriple = ({ title, paragraph1, paragraph2, paragraph3 }) => {
  return (
    <>
      <h4 className="h-priv">{title}</h4>
      <div className="p-priv">
        {paragraph1}
        <br />
        <br />
        {paragraph2}
        <br />
        <br />
        {paragraph3}
      </div>
    </>
  );
};

const PrivacyPolicy = ({ width }) => {
  return (
    <>
          <div className="popup__content popup__content--left popup__content--policy">
            <div className="h3 h3--light">Casper Association</div>
            <h3 className="h3">PRIVACY POLICY</h3>
            <div className="date-priv">Effective: 28 April 2022</div>
            <h4 className="h-priv">PUROPSE</h4>
            <div>
              This Privacy Policy (“Policy”) applies to personal information received by Casper Association, a Swiss
              corporation with its registered seat at Baarerstrasse 10, 6300 Zug, Switzerland (CHE-481.582.648) (“Casper
              Association”, "we", "us"). This Policy governs your visit to the{" "}
              <a href="https://casper.network/network/casper-association" className="link-priv" target="_blank">
                website
              </a>{" "}
              (“Website”).
              <br /> <br /> Casper Association is the entity that determines the collection and use of personal
              information on the Website. As such we are the “controller” for purposes of privacy laws and regulations.
              If you have any questions about this Policy, you may contact us at Baarerstrasse 10, 6300 Zug,
              Switzerland. This Policy provides you with notice about how we collect and use your personal information,
              as well as what rights you have and how to exercise those rights.
            </div>
            <PrivItemDouble
              title={"Specific Information about this Policy"}
              paragraph1={`
              This Policy is described in a concise, transparent, intelligible, and easily accessible form. It is set forth in a series
               of specific components describing how the Policy operates and how it meets certain privacy rights.
            `}
              paragraph2={`
              This Policy describes what personal information (as we describe further below) we collect about you, how 
              your personal information may be used and shared (if at all), and how your personal information will be stored,
               and how you can access, modify, and if needed, request deletion of your personal information.
            `}
            />
            <PrivItem
              title={"Changes to this Privacy Notice"}
              paragraph1={`
              We reserve the right to revise this Policy at any time. We will notify you of any material changes to 
              the Policy by providing a link to the new Policy on our Website. Therefore, we encourage you to periodically
               read this Policy to check for any revisions. We recommend that you store a copy of this Policy and any future versions
                that may apply to you from time to time for your records.
            `}
            />
            <PrivItem
              title={"Data Privacy Statement"}
              paragraph1={`
                This Policy applies to any individuals who access and use the Website. The privacy of these individuals is of 
                utmost importance for Casper Association. This Policy applies to every individual, regardless of where
                you reside, who has dealings with us that results in the collection and processing of personal information.
                However, please be aware that if you reside in Switzerland, this Policy is designed to meet the requirements
                of the Swiss Federal Act on Data Protection (“FADP”), including the Ordinance to the Federal Act on Data
                Protection (“OFADP”), as well as the Telecommunications Act (“TCA”); if you are a resident of the European
                Economic Area, this explanation and summary of the Policy is designed to meet the requirements of the
                General Data Protection Regulation (“GDPR”). If you are a resident of the United States, this explanation
                and summary of the Policy is designed to meet the requirements of the California Consumer Privacy Act
                (“CCPA”), which became effective on January 1, 2020. If you are a resident of Canada, this Policy is
                designed to meet the requirements of the Personal Information Protection and Electronic Data Act (“PIPEDA”),
                and individual Provincial privacy laws.`}
            />
            <h4 className="h-priv">Definitions</h4>
            <div>
              "Personal Information” means information relating to an identified or identifiable individual or under the
              current FADP legal entity, such as Personal Information that we obtain about you when you interact with us
              or provide us information via the Website or the functionalities therein. Personal Information does not
              include any data that is anonymized or data that cannot identify you in any way.
              <br />
              <br />
              The types of Personal Information we may collect include:
              <br />
              <ul className="ul-priv">
                <li className="li-priv">
                  Internet Protocol (IP) address associated with a computing device that you use to access our Website
                  as well as date and time of access, name and URL of the data accessed, the website from which access
                  is made to our domain, your computer’s operating system and the browser you use, the country from
                  which access to our Website is made, and the name of your Internet provider.
                </li>
                <li className="li-priv">Your email address if you subscribe for the newsletter.</li>
              </ul>
            </div>
            <PrivItem
              title={"How we collect your Personal Information"}
              paragraph1={`
                We may collect Personal Information about you that you voluntarily agree to provide
                 when you access and use the Website. Such collection may occur when: (1) you visit our
                  Website (so-called log files), (2) you subscribe for a newsletter, (3) you participate 
                  in discussions or post contributions in member forums on the Website, in case that such discussions are available.`}
            />
            <h4 className="h-priv">How we use your Personal Information</h4>
            <div>
              We use your Personal Information for various specific purposes, including to:
              <br />
              <br />
              <ul className="ul-priv">
                <li className="li-priv">
                  Enable the use of the Website (connection establishment), to ensure the long-term security and
                  stability of the system and to optimize the Website, and for internal statistical purposes. However,
                  such information will not be linked to or stored with Personal Information. Only in the case of an
                  attack on the network infrastructure of our Website or in case of suspicion of other unauthorised or
                  improper use of the Website, the IP address will be evaluated for clarification and defence and, if
                  necessary, used in criminal proceedings for identification and to bring legal action against the users
                  concerned under civil and criminal law. In this case, we have a legitimate interest according to art.
                  6 para. 1 lit. f GDPR.
                </li>
                <li className="li-priv">
                  Operate, administer, and display your posts and discussion contributions based on your consent
                  according to art. 6 para. 1 lit. a GDPR.{" "}
                </li>
                <li className="li-priv">
                  Deliver you newsletters based on your consent according to art. 6 para. 1 lit. a GDPR or based on our
                  legitimate interest according to art. 6 para. 1 lit. f GDPR in case that there is already a
                  relationship with you. You can revoke your consent at any time for the future. In case of a legitimate
                  interest for the data processing you can oppose to the data processing at any time for the future.
                </li>
              </ul>
            </div>
            <PrivItemDouble
              title={"Disclosing your Personal Information to Third Parties"}
              paragraph1={`
              Casper Association does not sell your Personal Information to third parties
            `}
              paragraph2={`
              However, various third-party service providers are explicitly mentioned in this Policy. 
              We may share your Personal Information to our subsidiaries or non-related third-party service providers
               that perform services on our behalf, such as the web-hosting company [Zoho Site Content] and the company where user data is stored.
                The data are stored in Switzerland. These activities are for the purpose of ensuring the proper operation of our Website.
                In these cases, we process your Personal Information based on our legitimate interest according to art. 6 para. 1 lit. f GDPR.
            `}
            />
            <PrivItemTriple
              title={"Cross-Border Transfers"}
              paragraph1={`
            In some instances, the disclosure of your Personal Information may require us to transfer your Personal 
            Information to third parties outside of Switzerland, including to the United States.
            `}
              paragraph2={`
            For the sake of completeness, we would like to point out that the U.S. authorities may take surveillance measures under U.S.
             law that allow general storage of all data transferred from the European Union or Switzerland to the United States.
              This is done without distinction, limitation or exception, on the basis of the objective pursued and without objective 
              criteria that would allow limiting the access of the U.S. authorities to Personal Information and their subsequent use to specific, 
              strictly limited purposes that justify access to such data. In addition, we would like to point out that there are no legal remedies 
              in the U.S. for data subjects from EU Member States or Switzerland that would allow them to obtain access to the data concerning them and 
              to obtain its correction or deletion, and that there is no effective legal protection against general access rights of the U.S. authorities. 
              We expressly draw the data subject's attention to this legal and factual situation so that he or she can make an informed decision about 
              consenting to the use of his or her data.`}
              paragraph3={`
              When we transfer your Personal Information to another country, we ensure that the recipient is able to provide the same adequate protection
               of that data as we provide, through internal standard contractual clauses or other adequate safeguards.`}
            />
            <PrivItem
              title={"Links to other Websites and Third-Party content"}
              paragraph1={`
            We may provide links to or embed content hosted by third-party websites, services, and applications that operate outside the control of Casper Association.
             Third-party services may include an activity feed, social media buttons, and widgets. This Policy does not address the privacy,
              security, or other practices of third parties that provide such content.
             We encourage you to review the privacy policies of those third parties before providing any information to us through them.`}
            />
            <h4 className="h-priv">Links to our social media presences</h4>
            <div className="p-priv">
              We have incorporated links to our social media profiles on the following networks:
              <ul className="ul-priv">
                <li className="li-priv">
                  GitHub of GitHub Inc., 88 Colin P Kelly Jr Street, San Francisco, CA 94107, USA;
                </li>
                <li className="li-priv">
                  Telegram of Telegram Messenger LLP, 71-75 Shelton Street Covent Garden, London, UK;
                </li>
                <li className="li-priv">
                  Discord of Discord Inc., 444 De Haro Street Suite 200, San Francisco, CA 94107, USA;
                </li>
                <li className="li-priv">
                  Twitter of Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA.
                </li>
                <li className="li-priv">LinkedIn</li>
                <li className="li-priv">Facebook</li>
                <li className="li-priv">Youtube</li>
              </ul>
              <br />
              <div>
                If you click on the relevant social network icons, you will be automatically redirected to our profile
                on the relevant network. In order to use the functions of the relevant network there, you must partially
                log in to your user account for the relevant network. When you open a link to one of our social media
                profiles, a direct connection is established between your browser and the server of the relevant social
                network. This gives the network the information that you have visited our Website with your IP address
                and accessed the link. If you access a link to a network while logged in to your account on the relevant
                network, the contents of our page may be linked to your profile in the network, which means that the
                network can link your visit to our Website directly to your user account. If you want to prevent this,
                you should log out before clicking on the relevant links. An assignment takes place in any case, if you
                log into the relevant network after clicking on the link.
              </div>
            </div>
            <h4 className="h-priv">Your Choices</h4>
            <div className="p-priv">
              <ul className="ul-priv">
                <li>
                  <b>Disabling Cookies:</b> You may choose to disable our cookies via your internet browsers and
                  computing devices. Most browsers and computing devices include settings that allow you to clear or
                  decline cookies. The following pages explain how to configure the processing of cookies for the most
                  common browsers:
                  <br />
                  <ul className="ul-priv ul-priv--inner">
                    <li className="link-priv">
                      <a
                        href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                        target="_blank"
                        className="link-priv"
                      >
                        Microsoft Windows Internet Explorer
                      </a>
                    </li>
                    <li className="link-priv">
                      <a
                        href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                        target="_blank"
                        className="link-priv"
                      >
                        Microsoft Windows Internet Explorer Mobile
                      </a>
                    </li>
                    <li className="link-priv">
                      <a
                        href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox"
                        target="_blank"
                        className="link-priv"
                      >
                        Mozilla Firefox
                      </a>
                    </li>
                    <li className="link-priv">
                      <a
                        href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
                        target="_blank"
                        className="link-priv"
                      >
                        Google Chrome for Desktop
                      </a>
                    </li>
                    <li className="link-priv">
                      <a
                        href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&hl=en"
                        target="_blank"
                        className="link-priv"
                      >
                        Google Chrome for Mobile
                      </a>
                    </li>
                    <li className="link-priv">
                      <a
                        href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
                        target="_blank"
                        className="link-priv"
                      >
                        Apple Safari for Desktop
                      </a>
                    </li>
                    <li className="link-priv">
                      <a href="https://support.apple.com/en-us/HT201265" target="_blank" className="link-priv">
                        Apple Safari for Mobile
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="li-priv">
                  If you disable cookies, however, some of the features of our Website may not function properly.
                </li>
              </ul>
              <br />
            </div>
            <h4 className="h-priv">Your Rights and Obligations</h4>
            <h4 className="h-priv h-priv--italic">Your Rights and Obligations</h4>
            <div className="p-priv">
              It is important that the Personal Information we hold about you is accurate and current. Please keep us
              informed if your Personal Information changes during the period in which our relationship is active.
              <br />
              <br />
              <div className="h-priv h-priv--italic">Your rights in connection with Personal Information</div>
              <p> Under certain circumstances, under applicable privacy laws, you have the right to:</p>
              <ul className="ul-priv ul-priv--numbered">
                <li className="li-priv">
                  <b>Request access</b> to your Personal Information. This enables you to receive a copy of the Personal
                  Information we hold about you and to check that we are processing it lawfully.
                </li>
                <li className="li-priv">
                  <b>Request correction</b> of the Personal Information. This enables you to request that we correct
                  errors in your Personal Information.
                </li>
                <li className="li-priv">
                  <b>Object to processing</b> of your Personal Information where we are relying on a legitimate interest
                  (or those of a third party) and there is something about your particular situation that causes you to
                  object to processing on this ground.{" "}
                </li>
                <li className="li-priv">
                  <b>Request the restriction of processing</b> of your Personal Information. This enables you to ask us
                  to suspend the processing of Personal Information about you, for example, if you want us to establish
                  its accuracy or reason for processing it.
                </li>
                <li className="li-priv">
                  <b>Request the transfer</b> of your Personal Information to another party, which we may be able to do
                  if it is feasible to do so (e.g., if such a transfer can be accomplished technically) or if it is
                  appropriate to do so (e.g., if the other entity is willing to accept your Personal Information
                  directly from us).
                </li>
                <li className="li-priv">
                  <b>Right to file a complaint</b> with the competent data protection authority at any time.
                </li>
                <li className="li-priv">
                  <b>A final right you have is to request erasure</b> of your Personal Information. This right enables
                  you to ask us to delete or remove Personal Information where there is no good reason for us continuing
                  to process it. You also have the right to ask us to delete or remove your Personal Information where
                  you have exercised your right to object to processing. However, you should be aware there are specific
                  exemptions under which Casper Association will NOT honor a request for erasure:
                  <ul className="ul-priv ul-priv--inner">
                    <li>
                      Where we have an ongoing legal obligation to retain your Personal Information because of a
                      regulatory requirement;
                    </li>
                    <br />
                    <li>
                      Where the continued use of your Personal Information is necessary in order to detect security
                      incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute
                      those responsible for such activities.
                    </li>
                  </ul>
                </li>
              </ul>
              <br />
              <div>
                If you want to review, verify, correct, or request erasure of your Personal Information, object to the
                processing of your Personal Information, revoke your consent or request that we transfer a copy of your
                Personal Information to another party, please contact our data protection manager in writing.
              </div>
            </div>

            <h4 className="h-priv">Information for European Economic Area and Swiss Residents</h4>
            <div>
              If you are a resident of Switzerland or a country in the European Economic Area (“EEA”), this portion of
              our Policy advises you of your rights and protections under applicable law regarding the processing of
              your “personal data,” as defined by the Swiss Federal Act on Data Protection (“FADP”), including the
              Ordinance to the Federal Act on Data Protection (“OFADP”), the Telecommunications Act (“TCA”), and the
              European General Data Protection Regulation (“GDPR”). When we process your personal data, we will only do
              so when we have your consent or if we can rely on any other legal basis permitting the processing of
              personal data. When processing is based on consent, you have the right to revoke your previously granted
              consent at any time with effect for the future by sending an information via the contact form on the
              Website or by sending an email to [
              <a href="mailto:info@casper.network" className="link-priv">
                info@casper.network
              </a>
              ].
            </div>
            <br />
            <div>
              We will only retain your Personal Information for as long as necessary to fulfil the purposes we collected
              it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. Details
              of retention periods for different aspects of your Personal Information are available in our retention
              policy, which is available from us. To determine the appropriate retention period for Personal
              Information, we consider the amount, nature, and sensitivity of the Personal Information, the potential
              risk of harm from unauthorized use or disclosure of your Personal Information, the purposes for which we
              process your Personal Information and whether we can achieve those purposes through other means, and the
              applicable legal requirements. In some circumstances, we may anonymize your Personal Information so that
              it can no longer be associated with you, in which case we may use such information without further notice
              to you. When permitted or it is appropriate to do so, we will securely destroy your Personal Information
              in accordance with applicable laws and regulations. There may be specific statutory data retention
              obligations. Swiss law requires, for example, that business communication, concluded contracts and booking
              receipts must be stored for up to 10 years.
            </div>
            <br />
            <div>
              We are entitled to pass on your personal data to third-party companies abroad as described above under
              "Disclosing your personal data to Third Parties". These third-party companies are obliged to respect your
              privacy to the same extent as we do. If the level of data protection in a country is considered inadequate
              within the meaning of the FADP or the GDPR, we will ensure, by means of a contract, that your personal
              data is protected according to Swiss regulations or the GDPR at all times.
            </div>
            <br />
            <div>Our EU representative according to art. 27 GDPR is:</div>
            <br />
            <div>MLL EU-GDPR GmbH </div>
            <br />
            <div>Ganghoferstrasse 37</div>
            <br />
            <div>
              DE-80339 Munich
              <br />
              <a href="mailto:casperassociation@mll-gdpr.com" className="link-priv">
                casperassociation@mll-gdpr.com
              </a>
            </div>
            <div className="h-priv">Information for California Consumers</div>
            <div>
              This portion of our Policy advises California residents of rights provided under the California Consumer
              Privacy Act (the “CCPA”) that relate to our collection, use, and disclosure of Personal Information.{" "}
            </div>
            <br />
            <div>
              Subject to certain limitations, you have the right to request to know more about the Personal Information
              (and categories of information) we collect, use, and disclose, and to request the deletion of your
              Personal Information. To make any such a request, please use the contact form on the Website or send an
              email to [
              <a href="mailto:info@casper.network" className="link-priv">
                info@casper.network
              </a>
              ].
            </div>
            <br />
            <div>
              You also have the right to opt-out of the disclosure of Personal Information to third parties for their
              direct marketing purposes. Casper Association does not disclose Personal Information to third parties for
              direct marketing purposes. California law (California Civil Code Section 1798.83) “Shine the Light Law”
              permits residents of California to opt out of our disclosures of your Personal Information to third
              parties for marketing purposes at any time. Casper Association will not disclose your personally
              identifiable information to third parties for marketing without giving you notice and getting your
              consent. Please note this opt-out doesn’t prohibit disclosures for nonmarketing purposes. You can opt out
              for free by contacting us via the contact form on the Website or at [info@casper.network] or writing to
              writing to us at: Baarerstrasse 10, 6300 Zug, Switzerland.
            </div>
            <br />
            <div>
              Some website browsers may be able to send a do not track signal to websites. Our Website does not respond
              to do not track signals or similar mechanisms and requests. If you block cookies through your browser, you
              will block cookies, as noted above.
            </div>
            <PrivItem
              title={"Information for Canadian Residents"}
              paragraph1={`
            Individuals covered by PIPEDA, or any of the six provincial privacy laws that have been declared “substantially similar” to PIPEDA,
             must obtain an individual’s consent when they collect, use, or share the individual’s Personal Information, and individuals have a 
             right to access their Personal Information held by an organization and to challenge its accuracy, if need be. Casper Association can 
             only use Personal Information for the purpose(s) for which it was collected. Individuals should also be assured that their Personal 
             Information will be protected by appropriate safeguards.
            `}
            />

            <PrivItemDouble
              title={"Information and Privacy Security"}
              paragraph1={`
            Casper Association uses a variety of physical, administrative, and technological safeguards designed to protect your Personal 
            Information against loss, misuse, and unauthorized access or disclosure and follows the applicable legal and regulatory requirements 
            for safeguarding such information. We have dedicated information security programs and work hard to continuously enhance our technical
             and operational security measures. Our measures consider the sensitivity of the information we collect, use, and store, and the current 
             state of technology. Our security measures include data encryption, firewalls, data use and access limitations for our personnel and 
             subcontractors and physical access controls to our facilities  and cloud-based data storage servers.
            `}
              paragraph2={`Please be aware that, despite our best efforts, no security measures are perfect. As a result, we cannot guarantee or
             warrant the security of any submitted Information. In the event of a security breach that involves your Personal Information,
              Casper Association will notify the appropriate officials and notify you if you have reason to believe that your data has been compromised.`}
            />
            <h4 className="h-priv">Affirmative Consent and Withdrawal Right</h4>
            <div>
              I have read the above information presented by Casper Association about this Policy, including the use of
              cookies. I hereby give affirmative consent to Casper Association to collect and use my Personal
              Information in connection with the Website. I am aware that I have the right to withdraw my consent at any
              time with effect for the future, by using the contact form or by e-mail to [info@casper.network] or in
              writing to Casper Association at its mailing address: Baarerstrasse 10, 6300 Zug, Switzerland
            </div>
          </div>
    </>
  );
};

export default PrivacyPolicy;
