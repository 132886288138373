import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { CLPublicKey } from "casper-js-sdk";

import Topbar from "./components/topbar";
import Footer from "./components/footer";
import Cards from "./components/cards";
import Banner from "./components/banner";
import Faq from "./components/faq";
import Account from "./components/account";
import TigerDetails from "./components/tiger-details";
import { SignerScreen, SignerConnector } from "./components/signer";
import CookiesPopup from "./components/cookies-popup";
import useLocalStorage from "./hooks/UseLocalStorage";

import "./style/App.scss";

import { MIDDLEWARE_URL } from "./constants";

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [introFinished, setIntroFinished] = useState(false);
  const [deleyTime, setDeleyTime] = useState(3000);
  const [NFTs, setNFTs] = useState([]);
  const [signerAccountHash, setAccountHash] = useState(null);
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage("cookies-accept", false);

  const setSignerAccountHash = (publicKey) => {
    if (publicKey) {
      const hash = CLPublicKey.fromHex(publicKey).toAccountHashStr().slice(13);
      setAccountHash(hash);
    } else {
      setAccountHash(null);
    }
  };

  //Window size listener
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  //Change flip card delay on finish intro animation
  useEffect(() => {
    if (introFinished) {
      setDeleyTime(0);
    }
  }, [introFinished]);

  const fetchAllNfts = () => {
    fetch(MIDDLEWARE_URL)
      .then((res) => res.json())
      .then((nfts) => {
        setNFTs(nfts);
      });
  };

  useEffect(() => {
    fetchAllNfts();
  }, []);

  //Claimed NFTs
  const claimedNfts = NFTs.length;

  return (
    <div className="App">
      {!cookiesAccepted ? <CookiesPopup setCookiesAccepted={setCookiesAccepted} /> : null}
      <SignerConnector setSignerAccountHash={setSignerAccountHash} />
      <Topbar signerAccountHash={signerAccountHash} width={width} />
      <Routes>
        <Route
          path="/"
          exact
          element={
            <>
              <Banner
                claimedNfts={claimedNfts}
                width={width}
                introFinished={introFinished}
                setIntroFinished={setIntroFinished}
              />
              <Cards deleyTime={deleyTime} setDeleyTime={setDeleyTime} NFTs={NFTs} />
            </>
          }
        />
        <Route path="/faq" element={<Faq width={width} />} />
        <Route path="/details/:id" element={<TigerDetails NFTs={NFTs} signerAccountHash={signerAccountHash} />} />
        {!signerAccountHash ? (
          <Route path="/*" element={<SignerScreen width={width} />} />
        ) : (
          <Route>
            <Route path="/account" element={<Account width={width} signerAccountHash={signerAccountHash} />} />
          </Route>
        )}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
