import React, { useState } from "react";
import range from "lodash.range";

import Pagination from "./pagination";
import Card from "./card";

import TigerBack from "../assets/tiger-back.svg";

const MAX_NFT_NUM_TO_DISPLAY = 100;
const NFTS_PER_PAGE = 20;

const Cards = ({ deleyTime, setDeleyTime, NFTs }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastNft = currentPage * NFTS_PER_PAGE;
  const indexOfFirstNft = indexOfLastNft - NFTS_PER_PAGE;
  // const currentNfts = NFTs.slice(indexOfFirstNft, indexOfLastNft);

  //Page numbers
  const maxPageNumber = Math.ceil(MAX_NFT_NUM_TO_DISPLAY / NFTS_PER_PAGE);

  // const pageNumbers = [];
  // for (let i = 1; i <= Math.ceil(NFTs.length / NFTS_PER_PAGE); i++) {
  //   pageNumbers.push(i);
  // }

  //Change page
  const nextPage = (pageNum) => {
    if (pageNum < maxPageNumber) {
      setCurrentPage(pageNum + 1);
      setDeleyTime(0);
    }
  };
  const prevPage = (pageNum) => {
    if (pageNum - 1 > 0) {
      setCurrentPage(pageNum - 1);
      setDeleyTime(0);
    }
  };

  return (
    <>
      <div className="wrapper wrapper--mt0">
        <div className="wrapper__bg">
          <Pagination currentPage={currentPage} nextPage={nextPage} prevPage={prevPage} maxPageNumber={maxPageNumber} />
          <div className="cards">
            {range(indexOfFirstNft, indexOfLastNft).map(idx => {
              const nftToShow = NFTs.find(n => parseInt(n.id) === idx + 1);
              return nftToShow ? (
                <Card nft={nftToShow} key={idx} deleyTime={deleyTime} setDeleyTime={setDeleyTime} />) : 
                (<img src={TigerBack} alt="tiger-back" className="card" key={idx} />)
            })}
          </div>
          <Pagination currentPage={currentPage} nextPage={nextPage} prevPage={prevPage} maxPageNumber={maxPageNumber} />
        </div>
      </div>
    </>
  );
};

export default Cards;
