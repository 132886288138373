import React from "react";
import { Link } from "react-router-dom";

import tigersLogo from ".././assets/casper-tigers-logo.svg";
import WalletConnected from ".././assets/wallet-connected.svg";
import WalletDisconnected from ".././assets/wallet-disconnected.svg";
import WalletConnectedMobile from ".././assets/wallet-connected-mobile.svg";
import WalletDisconnectedMobile from ".././assets/wallet-disconnected-mobile.svg";

const Topbar = ({ signerAccountHash, width }) => {
  return (
    <div className="topbar">
      <div className="topbar__content">
        <Link to="/">
          <img src={tigersLogo} alt="tigers-logo" />
        </Link>
        <div className="topbar__right-wrapper">
          {width < 769 ? (
            <img
              src={signerAccountHash ? WalletConnectedMobile : WalletDisconnectedMobile}
              alt="wallet"
              className="wallet"
            />
          ) : (
            <>
              <img src={signerAccountHash ? WalletConnected : WalletDisconnected} alt="wallet" className="wallet" />
              <Link to="/account" className="btn btn--redeem btn--pt">
                Account
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
