import React from "react";
import { TwitterShareButton } from "react-share";
import { FaTwitter } from "react-icons/fa";

const ShareBtn = ({ tiger }) => {
  return (
    <TwitterShareButton
      title={"Check out this CasperTiger NFT!"}
      url={`http://caspertigers.io/details/${tiger.id}`}
      hashtags={["CasperTigers", "NFT", "CasperAssociation", "CasperNetwork", "CasperBlockchain"]}
    >
      <div className="btn btn--twitter btn--w">
        <FaTwitter size={28} /> Share on Twitter
      </div>
    </TwitterShareButton>
  );
};

export default ShareBtn;
