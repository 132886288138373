import img_0 from "../assets/anim-intro-loop-mobile/img_0.png";
import img_1 from "../assets/anim-intro-loop-mobile/img_1.png";
import img_2 from "../assets/anim-intro-loop-mobile/img_2.png";
import img_3 from "../assets/anim-intro-loop-mobile/img_3.png";
import img_4 from "../assets/anim-intro-loop-mobile/img_4.png";
import img_5 from "../assets/anim-intro-loop-mobile/img_5.png";
import img_6 from "../assets/anim-intro-loop-mobile/img_6.png";
import img_7 from "../assets/anim-intro-loop-mobile/img_7.png";
import img_8 from "../assets/anim-intro-loop-mobile/img_8.png";
import img_9 from "../assets/anim-intro-loop-mobile/img_9.png";
import img_10 from "../assets/anim-intro-loop-mobile/img_10.png";

export const animIntroLoopMobile = {
  v: "5.7.8",
  fr: 30,
  ip: 76,
  op: 264,
  w: 375,
  h: 612,
  nm: "CT - motion - HomePage - introLoop - MOBILE",
  ddd: 0,
  assets: [
    { id: "image_0", w: 69, h: 73, u: "", p: img_0, e: 0 },
    { id: "image_1", w: 178, h: 641, u: "", p: img_1, e: 0 },
    { id: "image_2", w: 266, h: 226, u: "", p: img_2, e: 0 },
    { id: "image_3", w: 991, h: 27, u: "", p: img_3, e: 0 },
    { id: "image_4", w: 712, h: 441, u: "", p: img_4, e: 0 },
    { id: "image_5", w: 280, h: 217, u: "", p: img_5, e: 0 },
    { id: "image_6", w: 142, h: 199, u: "", p: img_6, e: 0 },
    { id: "image_7", w: 126, h: 305, u: "", p: img_7, e: 0 },
    { id: "image_8", w: 240, h: 310, u: "", p: img_8, e: 0 },
    { id: "image_9", w: 381, h: 216, u: "", p: img_9, e: 0 },
    { id: "image_10", w: 1110, h: 36, u: "", p: img_10, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 11,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 7.49, ix: 10 },
        p: { a: 0, k: [407.404, 215.271, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [111.738, 107.965, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 235,
      op: 238,
      st: 189,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 11,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 7.49, ix: 10 },
        p: { a: 0, k: [251.382, 224.69, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.082, 99.364, 100], ix: 6, l: 2 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [0, 0],
                [0, 73],
                [62.655, 81.162],
                [68.144, -8.852],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1",
        },
      ],
      ip: 235,
      op: 238,
      st: 189,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 11,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 7.49, ix: 10 },
        p: { a: 0, k: [407.404, 215.271, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [111.738, 107.965, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 229,
      op: 232,
      st: 183,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 11,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 7.49, ix: 10 },
        p: { a: 0, k: [251.382, 224.69, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.082, 99.364, 100], ix: 6, l: 2 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [0, 0],
                [0, 73],
                [62.655, 81.162],
                [68.144, -8.852],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1",
        },
      ],
      ip: 229,
      op: 232,
      st: 183,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 11,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 7.49, ix: 10 },
        p: { a: 0, k: [407.404, 215.271, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [111.738, 107.965, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 141,
      op: 144,
      st: 95,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: "eyelid masked.png",
      cl: "png",
      parent: 11,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 7.49, ix: 10 },
        p: { a: 0, k: [251.382, 224.69, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.5, 36.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.082, 99.364, 100], ix: 6, l: 2 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [0, 0],
                [0, 73],
                [62.655, 81.162],
                [68.144, -8.852],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1",
        },
      ],
      ip: 141,
      op: 144,
      st: 95,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "lamp - 02-logo.png",
      cl: "png",
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -222, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -212, s: [9.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -202, s: [-5.042] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -194, s: [3.198] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -187, s: [-1.394] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -180, s: [1.135] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -174, s: [-0.542] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -167, s: [0.414] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -160, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -152, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -142, s: [9.167] },
            { i: { x: [0.734], y: [-0.16] }, o: { x: [0.333], y: [0] }, t: -134, s: [-5.042] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.418], y: [0] }, t: -130, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -120, s: [90.076] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -100, s: [-47.449] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -80, s: [34.807] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -61, s: [-31.049] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -46, s: [22.625] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -32, s: [-16.43] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -19, s: [11.482] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -9, s: [-6.443] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 1, s: [3.76] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 11, s: [-3.042] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 20, s: [1.488] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 29, s: [-0.719] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 37, s: [0.791] },
            { t: 45, s: [0.269] },
          ],
          ix: 10,
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.621], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -130, s: [308.229] },
              { t: -114, s: [309.064] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.621], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -130, s: [42.61] },
              { t: -114, s: [-72.688] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [89, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [41.775, 41.775, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 3,
      nm: "MASTER NULL 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -75, s: [247.655] },
              { t: -49, s: [187.5] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -75, s: [220.152] },
              { t: -49, s: [337.121] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [41.775, 41.775, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -231,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: "hand L.png",
      cl: "png",
      parent: 15,
      refId: "image_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.451], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -130, s: [4.6] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -122, s: [-19.4] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -115, s: [13.776] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: -108, s: [-0.56] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -81, s: [-0.56] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -77, s: [-19.56] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -69, s: [30.951] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -50, s: [30.951] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -42, s: [-32.049] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -29, s: [16.219] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 6, s: [-9.049] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 76, s: [6.42] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 117, s: [16.931] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 178, s: [6.42] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 219, s: [21.045] },
            { t: 264, s: [6.42] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [155.086, 166.244, 0], ix: 2, l: 2 },
        a: { a: 0, k: [265, 145, 0], ix: 1, l: 2 },
        s: { a: 0, k: [97.352, 102.72, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: "Stick.png",
      cl: "png",
      parent: 9,
      refId: "image_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -130, s: [-0.439] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -122, s: [28.561] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -114, s: [-20.439] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -109, s: [6.561] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -104, s: [-3.652] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -100, s: [1.588] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -96, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -77, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -71, s: [-50] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -49, s: [2] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -24, s: [-20] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 9, s: [4] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 47, s: [-12.073] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 76, s: [-2.049] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 119, s: [-18.049] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 158, s: [2.716] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 220, s: [-22.622] },
            { t: 264, s: [-2.049] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [83.413, 110.489, 0], ix: 2, l: 2 },
        a: { a: 0, k: [495.5, 13.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: "head.png",
      cl: "png",
      parent: 15,
      refId: "image_4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.383], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -130, s: [-3.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.585], y: [0] }, t: -122, s: [-15.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -116, s: [-3.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -85, s: [-3.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -59, s: [-12.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -49, s: [-3.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -42, s: [-12.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -35, s: [-3.386] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 22.423, s: [3.458] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 55.714, s: [-7.343] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 99, s: [4.893] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 147, s: [-4.766] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 199, s: [3.458] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 244, s: [-7.343] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 287.286, s: [4.893] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 335.286, s: [-4.766] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 387.286, s: [3.458] },
            { t: 432.2861328125, s: [-7.343] },
          ],
          ix: 10,
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -49, s: [98.611] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -42, s: [139.794] },
              { t: -35, s: [98.611] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.487], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -130, s: [47.119] },
              { i: { x: [0.478], y: [1] }, o: { x: [0.548], y: [0] }, t: -122, s: [85.103] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -116, s: [47.119] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -49, s: [47.119] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -42, s: [110.425] },
              { t: -35, s: [47.119] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [388, 402.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.012, 99.988, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: "hand R.png",
      cl: "png",
      parent: 15,
      refId: "image_5",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -133, s: [-69.723] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -130, s: [20.641] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -125, s: [-29.084] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -119, s: [12.443] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -112, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -81, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -75, s: [33] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -72, s: [-86.217] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -49, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -46, s: [-40.119] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -41, s: [3.402] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -35, s: [-18.023] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -28, s: [3.402] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -22, s: [-5.96] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -9, s: [-0.96] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 25, s: [-12.96] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 52, s: [3.402] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 76, s: [-7.868] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 120, s: [7.872] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 170, s: [-25.683] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 220, s: [6.317] },
            { t: 264, s: [-7.868] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-6.561, 80.972, 0], ix: 2, l: 2 },
        a: { a: 0, k: [294, 192.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.63, 99.374, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 2,
      nm: "leg L.png",
      cl: "png",
      parent: 18,
      refId: "image_6",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.611], y: [0.758] }, o: { x: [0.324], y: [0.091] }, t: -129, s: [-4.862] },
            { i: { x: [0.413], y: [1] }, o: { x: [0.162], y: [0.727] }, t: -128, s: [10.471] },
            { i: { x: [0.578], y: [1] }, o: { x: [0.333], y: [0] }, t: -123, s: [21.138] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: -118, s: [-4.862] },
            { i: { x: [0.611], y: [0.758] }, o: { x: [0.324], y: [0.091] }, t: -81, s: [-4.862] },
            { i: { x: [0.413], y: [1] }, o: { x: [0.162], y: [0.727] }, t: -80, s: [10.471] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.355], y: [0] }, t: -75, s: [21.138] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -73, s: [-30.938] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -58, s: [-4.862] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -52, s: [-34.862] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -45, s: [23] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -37, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -9, s: [-12] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 22, s: [2.75] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 60, s: [-7.5] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 86, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 119, s: [-8.745] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 139, s: [2.051] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 180, s: [-6.496] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 218, s: [6.238] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 248, s: [-7.5] },
            { t: 274, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [43.578, -52.639, 0], ix: 2, l: 2 },
        a: { a: 0, k: [119, 29.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [34.05, 38.388, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 2,
      nm: "leg R.png",
      cl: "png",
      parent: 18,
      refId: "image_7",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.569], y: [0.539] }, o: { x: [0.203], y: [0.101] }, t: -131, s: [0] },
            { i: { x: [0.658], y: [0.814] }, o: { x: [0.312], y: [0.388] }, t: -130, s: [-46.3] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.351], y: [1.524] }, t: -129, s: [-86.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -128, s: [-91.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -127, s: [-94.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -126, s: [-95.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -125, s: [-95.6] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -124, s: [-95.8] },
            { i: { x: [0.687], y: [1] }, o: { x: [0.167], y: [0] }, t: -123, s: [-95.8] },
            { i: { x: [0.66], y: [1] }, o: { x: [0.167], y: [0] }, t: -118, s: [-86.8] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.351], y: [1.524] }, t: -81, s: [-86.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -80, s: [-91.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -79, s: [-94.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -78, s: [-95.15] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -77, s: [-95.6] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -76, s: [-95.8] },
            { i: { x: [0.585], y: [0.92] }, o: { x: [0.555], y: [0] }, t: -75, s: [-95.8] },
            { i: { x: [0.687], y: [1] }, o: { x: [0.265], y: [6.687] }, t: -71, s: [-12.946] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.351], y: [1.524] }, t: -49, s: [-9.478] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -48, s: [-14.478] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -47, s: [-17.478] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -46, s: [-18.478] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -45, s: [-18.928] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -44, s: [-19.128] },
            { i: { x: [0.57], y: [0.463] }, o: { x: [0.175], y: [0] }, t: -43, s: [-19.128] },
            { i: { x: [0.67], y: [-1.607] }, o: { x: [0.33], y: [2.607] }, t: -41, s: [-11.851] },
            { i: { x: [0.696], y: [12.488] }, o: { x: [0.354], y: [-9.123] }, t: -40, s: [-11.277] },
            { t: -39, s: [-11.453] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -50,
              s: [79.295, -30.352, 0],
              to: [0, -0.349, 0],
              ti: [0, 1.793, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -49,
              s: [79.295, -32.444, 0],
              to: [0, -1.793, 0],
              ti: [0, 2.042, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -48,
              s: [79.295, -41.11, 0],
              to: [0, -2.042, 0],
              ti: [0, 0.946, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -47,
              s: [79.295, -44.696, 0],
              to: [0, -0.946, 0],
              ti: [0, 0.498, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -46,
              s: [79.295, -46.788, 0],
              to: [0, -0.498, 0],
              ti: [0, 0.249, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -45,
              s: [79.295, -47.685, 0],
              to: [0, -0.249, 0],
              ti: [0, 0.05, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -44,
              s: [79.295, -48.282, 0],
              to: [0, -0.05, 0],
              ti: [0, -0.548, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -43,
              s: [79.295, -47.984, 0],
              to: [0, 0.548, 0],
              ti: [0, -1.195, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -42,
              s: [79.295, -44.995, 0],
              to: [0, 1.195, 0],
              ti: [0, -1.395, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -41,
              s: [79.295, -40.811, 0],
              to: [0, 1.395, 0],
              ti: [0, -1.345, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -40,
              s: [79.295, -36.628, 0],
              to: [0, 1.345, 0],
              ti: [0, -0.897, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: -39,
              s: [79.295, -32.743, 0],
              to: [0, 0.897, 0],
              ti: [0, -0.249, 0],
            },
            { t: -38, s: [79.295, -31.248, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [99, 8.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [34.016, 38.426, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 2,
      nm: "Chest.png",
      cl: "png",
      parent: 18,
      refId: "image_8",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -49, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -43, s: [9] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -38, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -9, s: [-3.004] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 22, s: [0.998] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 50, s: [-1.825] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 76, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 128, s: [-3.818] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 174, s: [2.432] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 228, s: [-4.288] },
            { t: 264, s: [0] },
          ],
          ix: 10,
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -49, s: [70.304] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -43, s: [80.886] },
              { t: -38, s: [70.304] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -49, s: [-48.021] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -43, s: [-42.044] },
              { t: -38, s: [-48.021] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50.006, 294.989, 0], ix: 1, l: 2 },
        s: { a: 0, k: [34.016, 38.426, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 2,
      nm: "tail.png",
      cl: "png",
      parent: 15,
      refId: "image_9",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: -134, s: [-14.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -130, s: [-36.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -123, s: [46.833] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -115, s: [-20.496] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -108, s: [-5.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -75, s: [-5.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -70, s: [74.833] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -49, s: [-47.854] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -41, s: [34.274] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -33, s: [-36.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -26, s: [3.708] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -19, s: [-8.125] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 14, s: [6.833] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 46, s: [-5.167] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 76, s: [0.355] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 129, s: [-5.944] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 201, s: [6.833] },
            { t: 264, s: [0.355] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [72.92, 273.331, 0], ix: 2, l: 2 },
        a: { a: 0, k: [2.009, 189.826, 0], ix: 1, l: 2 },
        s: { a: 0, k: [101.663, 111.464, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 3,
      nm: "Null 2",
      parent: 8,
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -75, s: [0] },
            { t: -49, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [101, -103, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 3,
      nm: "Null 1",
      parent: 17,
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.617], y: [0] }, t: -139, s: [-32] },
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: -129, s: [-368] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -119, s: [-368] },
              { i: { x: [0.564], y: [1] }, o: { x: [0.333], y: [0] }, t: -75, s: [-368] },
              { t: -49, s: [-54.095] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.948], y: [0.964] }, o: { x: [0.167], y: [0.167] }, t: -139, s: [-626] },
              { i: { x: [0.47], y: [1] }, o: { x: [0.048], y: [1.053] }, t: -129, s: [761] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -123, s: [787] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -118, s: [761] },
              { i: { x: [0.517], y: [0.686] }, o: { x: [0.145], y: [0] }, t: -81, s: [761] },
              { i: { x: [0.643], y: [0.827] }, o: { x: [0.3], y: [0.245] }, t: -80, s: [774.593] },
              { i: { x: [0.669], y: [1.005] }, o: { x: [0.328], y: [0.417] }, t: -79, s: [785.419] },
              { i: { x: [0.694], y: [1] }, o: { x: [0.352], y: [-0.009] }, t: -78, s: [789.545] },
              { i: { x: [0.694], y: [1] }, o: { x: [0.167], y: [0] }, t: -77, s: [792] },
              { i: { x: [0.45], y: [1] }, o: { x: [0.167], y: [0] }, t: -75, s: [792] },
              { i: { x: [0.944], y: [0.362] }, o: { x: [0.602], y: [0] }, t: -61, s: [261.502] },
              { i: { x: [0.47], y: [1] }, o: { x: [0.17], y: [0.736] }, t: -49, s: [622.85] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -43.336, s: [648.85] },
              { t: -38.3359375, s: [622.85] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [189, 167.311, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 2,
      nm: "shadow tiger.png",
      cl: "png",
      parent: 8,
      refId: "image_10",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: -133, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -129, s: [59.373] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -74, s: [59.373] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -71, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -61, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -53, s: [0] },
            { t: -49, s: [59.373] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.617], y: [0] }, t: -139, s: [110.907] },
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: -129, s: [-105.093] },
              { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: -119, s: [-105.093] },
              { i: { x: [0.564], y: [1] }, o: { x: [0.333], y: [0] }, t: -75, s: [-105.093] },
              { t: -49, s: [208.811] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -74, s: [675] },
              { t: -49, s: [650] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [555, 18, 0], ix: 1, l: 2 },
        s: { a: 0, k: [58.198, 58.198, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 76,
      op: 264,
      st: -231,
      bm: 0,
    },
  ],
  markers: [
    { tm: 11, cm: "1", dr: 0 },
    { tm: 76, cm: "3", dr: 0 },
    { tm: 182, cm: "2", dr: 0 },
  ],
};
