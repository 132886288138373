import React, { useEffect } from "react";
import Lottie from "react-lottie";

import { animDeploy } from "../assets/ct-motion-deployhash-loop";
import Text from "../data/text.json";
import Wrapper from "./wrapper";

const Deploy = ({ width, getStatus, hash }) => {
  const animDeployOptions = {
    loop: true,
    autoplay: true,
    animationData: animDeploy,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const interval = setInterval(() => getStatus(), 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Wrapper
        width={width}
        content={
          <div className="popup__content">
            <h3 className="h3">{Text.text40}</h3>
            <div className="font--normal">{Text.text41}</div>
            <div className="nft-data__hash">{hash}</div>
            <div className="clogo clogo--deploy">
              <Lottie options={animDeployOptions} />
            </div>
          </div>
        }
      />
    </>
  );
};

export default Deploy;
