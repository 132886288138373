import React from "react";
import { Link } from "react-router-dom";

import Cookies from "../assets/cookies.svg";
import Text from "../data/text.json";

const CookiesPopup = ({ setCookiesAccepted }) => {
  return (
    <div className="cookies">
      <div className="cookies__content">
        <button className="btn cookies__btn">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.1149 10L18.5649 3.56503C18.8474 3.28258 19.006 2.89949 19.006 2.50003C19.006 2.10058 18.8474 1.71749 18.5649 1.43503C18.2825 1.15258 17.8994 0.993896 17.4999 0.993896C17.1005 0.993896 16.7174 1.15258 16.4349 1.43503L9.99991 7.88504L3.56491 1.43503C3.28245 1.15258 2.89936 0.993896 2.49991 0.993896C2.10046 0.993896 1.71736 1.15258 1.43491 1.43503C1.15245 1.71749 0.993771 2.10058 0.993771 2.50003C0.993771 2.89949 1.15245 3.28258 1.43491 3.56503L7.88491 10L1.43491 16.435C1.29432 16.5745 1.18272 16.7404 1.10657 16.9232C1.03042 17.106 0.991211 17.302 0.991211 17.5C0.991211 17.6981 1.03042 17.8941 1.10657 18.0769C1.18272 18.2597 1.29432 18.4256 1.43491 18.565C1.57435 18.7056 1.74025 18.8172 1.92304 18.8934C2.10583 18.9695 2.30189 19.0087 2.49991 19.0087C2.69793 19.0087 2.89398 18.9695 3.07677 18.8934C3.25956 18.8172 3.42546 18.7056 3.56491 18.565L9.99991 12.115L16.4349 18.565C16.5744 18.7056 16.7403 18.8172 16.923 18.8934C17.1058 18.9695 17.3019 19.0087 17.4999 19.0087C17.6979 19.0087 17.894 18.9695 18.0768 18.8934C18.2596 18.8172 18.4255 18.7056 18.5649 18.565C18.7055 18.4256 18.8171 18.2597 18.8932 18.0769C18.9694 17.8941 19.0086 17.6981 19.0086 17.5C19.0086 17.302 18.9694 17.106 18.8932 16.9232C18.8171 16.7404 18.7055 16.5745 18.5649 16.435L12.1149 10Z"
              fill="#271B00"
            />
          </svg>
        </button>
        <div className="cookies__text">
          <div className="cookies__wrapper cookies__wrapper--ver">
            <h1 className="cookies__title">
              {Text.text36}
              <br />
              {Text.text37}
            </h1>
            <p className="cookies__p">
              {Text.text38}
              <Link to="/privacypolicy" className="hlink">
                {Text.text38a}
              </Link>
            </p>
          </div>
        </div>
        <div className="cookies__wrapper">
          <img src={Cookies} alt="cookies" className="cookies__img" />
        </div>
        <Link to="/" className="btn btn--redeem btn--w" onClick={() => setCookiesAccepted(true)}>
          {Text.text39}
        </Link>
      </div>
    </div>
  );
};

export default CookiesPopup;
