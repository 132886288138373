import React from "react";
import { Link } from "react-router-dom";

import ShareBtn from "./share-btn";

const TigerDesc = ({ tiger, info, tigerOwnerHash }) => {
  return (
    <div className="tiger__desc">
      <div className="nft-wrapper">
        <div className="nft-data">
          <div className="nft-data__head">NFT ID:</div>
          <div>{tiger.id}</div>
        </div>
        <div className="nft-data">
          <div className="nft-data__head">NFT BATCH:</div>
          <div>{tiger.metadata.batch}</div>
        </div>
      </div>
      <div className="nft-data--owner">
        <div className="nft-data__head">Owner:</div>
        <div className="nft-data__hash">{tigerOwnerHash}</div>
      </div>
      {info}
      <Link to="/" className="btn btn--redeem btn--w">
        Back to main page
      </Link>
      <ShareBtn tiger={tiger} />
    </div>
  );
};

export default TigerDesc;
