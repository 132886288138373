import React from "react";
import Lottie from "react-lottie";

import TigerDesc from "./tiger-desc";

import fireworks from "../assets/ct-motion-fireworks.json";
import TigerImg from "./tiger-img";

const Success = ({ tiger }) => {
  const fireworksOptions = {
    loop: 1,
    autoplay: true,
    animationData: fireworks,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (!tiger) {
    return null;
  }

  return (
    <>
      <div className="success">
        <div className="fireworks">
          <Lottie options={fireworksOptions} isClickToPauseDisabled={true} />
        </div>
        <TigerImg tiger={tiger} isDetail={false} />
        <TigerDesc tiger={tiger} tigerOwnerHash={tiger.owner.slice(13)} />
      </div>
    </>
  );
};

export default Success;
