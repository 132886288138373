import React from "react";
import { Link } from "react-router-dom";

import casperLogo from ".././assets/casper-association-logo.svg";
import blockbitesLogo from ".././assets/blockbites-logo.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__btns">
          <Link to="/faq" className="btn btn--footer">
            F.A.Q
          </Link>
          <Link to="/privacypolicy" className="btn btn--footer">
            Privacy Policy
          </Link>
          <Link to="//casper.network/en/network" target="_blank" className="btn btn--footer">
            Casper Association
          </Link>
          <Link to="//casper.network/network/trademark-policy" target="_blank" className="btn btn--footer btn--last">
            Trademark Policy & Guidelines
          </Link>
        </div>
        <div className="footer__logo">
          <Link to="//casper.network/en/network" target="_blank">
            <img src={casperLogo} alt="casper-logo" />
          </Link>
          <div className="footer__copyright">Ⓒ 2022 Casper Association</div>
          <div className="blockbites">
            <div className="blockbites__text">Made by</div>
            <Link to="//blockbit.es" target="_blank">
              <img className="blockbites__img" src={blockbitesLogo} alt="blockbites" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
